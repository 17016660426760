import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { screenDimensions } from "../../../utils/fileWithConstants";
import { Ionicons } from "@expo/vector-icons";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const TextButton = ({
	onPress,
	text,
	customStyle,
	customTextStyle,
	customButtonColor,
	disableButton,
	customButtonTextColor,
	arrowButtonEnabled,
}) => {
	const containerStyle = {
		...styles.container,
		...customStyle,
	};
	const textStyle = {
		...styles.text,
		...customTextStyle,
	};
	if (!customButtonColor && !disableButton) {
		containerStyle.backgroundColor = "#5252ff";
	} else if (!customButtonColor && disableButton) {
		containerStyle.backgroundColor = "rgba(82, 82, 255, 0.1)";
	}

	return (
		<TouchableOpacity onPress={onPress} style={{ marginVertical: 5 }} disabled={disableButton}>
			<View style={containerStyle}>
				<Text style={{ fontWeight: "bold", color: customButtonTextColor ? "#36454f" : "white" }}>{text}</Text>
				{arrowButtonEnabled ?
					<Ionicons
						style={
							styles.arrowIconStyle
						}
						name="chevron-forward-outline"
						type="ionicon"
					/>
					: <></>
				}
			</View>
		</TouchableOpacity>
	);
};
const styles = StyleSheet.create({
	container: {
		borderRadius: 10,
		height: SCREEN_HEIGHT * 0.06,
		width: SCREEN_WIDTH * 0.3,
		justifyContent: "center",
		alignItems: "center",
		alignContent: "center",
		backgroundColor: "#36454f",
	},
	arrowIconStyle: {
		position: "absolute",
		right: 20,
		fontSize: 20,
		color: "#36454f"
	}
});
export default TextButton;
