import React, { useState, useEffect, useRef } from "react";
import {
	View,
	Text,
	Image,
	Pressable,
	Appearance,
	Platform,
	Modal,
	Dimensions
} from "react-native";
import { FlatList } from "react-native-web";
import { Ionicons } from "@expo/vector-icons";
// import { SliderBox } from "react-native-image-slider-box";
import { screenDimensions } from "../../../utils/fileWithConstants";
import { ServicesAPI, UserAPI } from "../../../API";
import {
	handleNavigateOptions,
} from "../../../receivers/utils/handleNavigations";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { ServicePageStyles } from "../../../receivers/styles/ServicePageStyles";
import { IconButton, TextIconButtonCustom } from "../../Components/CustomButtons";
import { Calendar } from "react-native-calendars";
import { Button, Dialog } from "react-native-paper";
// import ReportUserMenu from "../../Components/Modal/ReportUserMenu";
import moment from "moment";
import { getFunctions, httpsCallable } from "firebase/functions";
import TextButtonWithImage from "../../Components/CustomButtons/TextButtonWithImage";
import { getAuth, signInAnonymously } from "firebase/auth";
import { TouchableOpacity } from "react-native-web";
import { Linking } from "react-native";
import { auth } from "../../../../index";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

export const ServicePage = ({ route, navigation }) => {
	var chatParams = useRef("");
	const cartItems = useRef([]);
	// const styles = ServicePageStyles;
	const [modalVisible, setModalVisible] = useState(false);
	const [employeeListModal, setEmployeeListModal] = useState(false);
	const [serviceList, setServiceList] = useState("");
	const [providerProfilePicture, setProviderProfilePicture] = useState([""]);
	const unavailableDays = useRef([]);
	const [providerSchedule, setProviderSchedule] = useState("");
	const [availabilitiesRoute, setAvailabilitiesRoute] = useState("");
	const [employeeList, setEmployeeList] = useState([]);
	const [providerInfo, setProviderInfo] = useState("");
	let DISABLED_DAYS = [];
	const [doc, setDoc] = useState("");
	const [loading, setLoading] = useState("");
	const [serviceInfo, setServiceInfo] = useState("");
	const [cart, setCart] = useState("");
	const [customerInfo, setCustomerInfo] = useState("");
	const [customerCardInfo, setCustomerCardInfo] = useState("");
	const [visible, setVisible] = React.useState(false);
	const [showReviews, setShowReviews] = useState("");
	const hideDialog = () => setVisible(false);
	const [reviewPossible, setReviewPossible] = useState(false);
	const [reviewToUpdate, setReviewToUpdate] = useState("");
	const [rating, setRating] = useState(5);
	const [subscriptionMarginTop, setSubscriptionMarginTop] = useState("");
	const [vacationList, setVacationList] = useState("");
	const noBookingDays = useRef({});
	const [providerQueryInfo, setProviderQueryInfo] = useState("");
	const anonymousAuth = useRef("");
	const [providerID, setProviderID] = useState("");
	const [countOfSubscriptionsIsZero, setCountOfSubscriptionsIsZero] = useState(false);
	const [SCREEN_HEIGHT, setSCREEN_HEIGHT] = useState(Dimensions.get('window').height);
	const [SCREEN_WIDTH, setSCREEN_WIDTH] = useState(Dimensions.get('window').width);

	const styles = {
		servicePageView: {
			height: SCREEN_HEIGHT,
			width: SCREEN_WIDTH * 0.8,
			overflow: "scroll"
		},

		ViewTextContainer: {
			backgroundColor: "white",
			height: 90,
			width: SCREEN_WIDTH,
			alignItems: "center",
			justifyContent: "center",
			position: "absolute",
			top: 0,
			borderColor: "white",
		},

		companyNameStyle: {
			color: "#36454f",
			fontSize: 30,
			fontWeight: "500",
			marginLeft: SCREEN_WIDTH * 0.02,
		},

		serviceHolder: {
			width: Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone') ? SCREEN_WIDTH * 1 : SCREEN_WIDTH * 0.7,
			height: SCREEN_HEIGHT * 0.08,
			marginLeft: SCREEN_WIDTH * 0.035,
			borderBottomColor: 'gray',
			boxShadow: '0 5px 5px -5px rgba(0, 0, 0, 0.3)',
			elevation: 5,
			justifyContent: "center",
		},

		serviceHolderSubscription: {
			width: SCREEN_WIDTH * 0.7,
			height: SCREEN_HEIGHT * 0.08,
			marginLeft: SCREEN_WIDTH * 0.035,
			borderBottomColor: 'gray',
			boxShadow: '0 5px 5px -5px rgba(0, 0, 0, 0.3)',
			elevation: 5,
			justifyContent: "center",
		},

		tinyLogo: {
			width: SCREEN_HEIGHT * 0.2,
			height: SCREEN_HEIGHT * 0.2,
			// position: "absolute",
			borderRadius: 20,
			marginLeft: SCREEN_WIDTH * 0.035,
		},

		quote: {
			width: SCREEN_WIDTH * 0.32,
			height: SCREEN_HEIGHT * 0.05,
			position: "absolute",
			top: SCREEN_HEIGHT * 0.165,
			left: SCREEN_WIDTH * 0.68,
			borderRadius: 15,
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "red"
		},
		interval: {
			flexDirection: "row",
		},
		intervalSubscription: {
			borderRadius: 15,
			flexDirection: "row",
		},
		inStore: {
		},

		serviceTitleContainer: {
			width: SCREEN_WIDTH * 0.75,
			flexDirection: "row",
			flexWrap: "wrap"
		},

		serviceTitleContainerSubscription: {
			fontWeight: "600",
			alignSelf: "center",
		},

		baseFontStyle: {
			color: "#36454f",
			fontSize: 16.5,
			fontWeight: "500",
		},

		baseFontStyleSubscription: {
			color: "",
			fontSize: 20,
		},

		servicePrice: {
			fontSize: 13,
			fontWeight: "500",
			color: "#36454f"
		},

		servicePriceSubscription: {
			fontSize: 13,
			fontWeight: "500",
			color: "#36454f"
		},

		serviceDescription: {
			fontWeight: "500",
			fontSize: 13,
			marginLeft: SCREEN_WIDTH * 0.01,
			color: "#36454f"
		},

		serviceDescriptionSubscription: {
			fontWeight: "500",
			fontSize: 13,
			color: "#36454f",
		},

		requireQuote: {
			fontSize: 13,
			fontWeight: "500",
			color: "#36454f",
		},

		modalStyle: {
			backgroundColor: "transparent",
			marginTop: SCREEN_HEIGHT < 700 ? SCREEN_HEIGHT * 0.25 : SCREEN_HEIGHT * 0.35,
		},

		containerContent: { flex: 1, marginTop: "90%" },
		headerContent: {
			marginTop: "10%",
			alignContent: "center",
			alignItems: "center",
			justifyContent: "center",
		},
		sectionHeader: {
			fontSize: 23,
			fontWeight: "normal",
			marginBottom: 10,
			marginTop: 15,
			backgroundColor: "#ebedf0",
			backgroundColor: "red"
		},
		doneTextButton: {
			width: 50,
			height: 50,
			borderRadius: 100,
			backgroundColor: "transparent",
			position: "absolute",
			top: "0.1%",
			right: "5%",
		},
		doneTextButtonTextStyle: {
			fontSize: 40,
			color: "#36454f",
			fontWeight: "bold",
			alignSelf: "center",
		},
		calendarModal: {
			backgroundColor: "white",
			width: SCREEN_WIDTH,
			// height: "100%",
			position: "absolute",
			bottom: 0
		},
		providerModal: {
			height: SCREEN_HEIGHT * 0.6,
			overflow: "scroll",
			// width: SCREEN_WIDTH,
			backgroundColor: "white",
			alignItems: "center",
			position: "absolute",
			bottom: 0,
			left: 0
		},
		customIconButton: {
			// borderRadius: 100,
			// backgroundColor: "transparent",
			// position: "absolute",
			// top: SCREEN_HEIGHT * 0.01,
			// left: SCREEN_HEIGHT * 0.15,
		},
		customIconButtonText: {
			fontSize: 44,
			color: "#2f2f36",
			fontWeight: "bold",
		},
	}

	useEffect(() => {
		setLoading(true);
		getProviderIDFromURL();

		const handleResize = () => {
			if (Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone')) {
				setSCREEN_HEIGHT(Dimensions.get('screen').height);
				setSCREEN_WIDTH(Dimensions.get('screen').width);
			} else {
				setSCREEN_HEIGHT(Dimensions.get('window').height);
				setSCREEN_WIDTH(Dimensions.get('window').width);
			}
		};

		Dimensions.addEventListener('change', handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			Dimensions.removeEventListener('change', handleResize);
		};
	}, []);

	const signUserInAnonymously = async () => {
		await signInAnonymously(auth)
			.then((user) => {

			})
			.catch((error) => {
				console.log("error: ", error)
				// ...
			});
	}

	const getProviderIDFromURL = async () => {
		let providerIDTemp = "";
		let urlArrayWithID = "";

		await Linking.getInitialURL().then(async (url) => {
			let urlTemp = JSON.stringify(url);
			let urlArray = Array.from(urlTemp)
			const indexToSpliceFrom = urlArray.findIndex((char) =>
				char === "="
			);

			if (indexToSpliceFrom != -1) {
				urlArrayWithID = urlArray.slice(indexToSpliceFrom + 1, urlArray.length - 1);
				providerIDTemp = urlArrayWithID.join("");
				fetchServices(providerIDTemp);
				fetchUserInfo(providerIDTemp);
				fetchEmployeeList(providerIDTemp);
			}

		});
	}

	const fetchServices = async (providerID) => {
		await ServicesAPI.fetchServicesByID(providerID).then(
			(serviceList) => {
				let countOfSubscriptions = 0;
				serviceList.forEach((service) => {
					if (
						service.priceUnit == "month" ||
						service.priceUnit == "bi-weekly" && service.priceID == "" && service.productID == ""
					) {
						countOfSubscriptions += 1;
					}
				});

				if (countOfSubscriptions == 0) {
					setSubscriptionMarginTop(0);
					setCountOfSubscriptionsIsZero(true);
				} else {
					setCountOfSubscriptionsIsZero(false);
				}
				setServiceList(serviceList);
			}
		);
	};

	const fetchAvailabilityAndSchedule = async (date, providerInfoTemp) => {
		await UserAPI.fetchUserDocByID(providerInfoTemp.userID).then((res) => {
			fetchUserAvailabilities(res, { dateString: date });
			setDoc(res);
		});
	};

	const fetchUserInfo = async (providerID) => {
		UserAPI.fetchUserByID(providerID).then((user) => {
			if (providerProfilePicture != undefined) {
				setProviderProfilePicture(user.image);
			} else {
				setProviderProfilePicture([]);
			}
			setProviderQueryInfo(user);
		});
	};

	const fetchEmployeeList = async (providerID) => {
		await UserAPI.fetchUserByIDWithDocID(providerID).then(
			(query) => {
				setEmployeeList(query.user.employeeList);
			}
		);
	};

	const stripeWebTest = async () => {
		fetch('https://us-central1-serveyou-auth.cloudfunctions.net/stripeWebRedirect')
			.then(response => response.text())
			.then(data => console.log(data))
			.catch(error => console.error(error));
		return response;
	};

	const handleCalendarOnPress = (day) => {
		const dateTemp = moment(day.dateString).format("YYYY-MM-DD");

		if (providerQueryInfo.mobileLocation) {
			navigation.navigate("Availabilities", {
				// currName: route.params.currName,
				date: dateTemp,
				userID: providerQueryInfo.userID,
				companyName: providerQueryInfo.companyName,
				cartItems: cartItems,
				cartOptions: [
					{
						priceID: serviceInfo.priceID,
						cartItems: cartItems.current.name,
						price: cartItems.current.price,
					},
				],
				stripeConnectAccount: providerQueryInfo.stripeConnectAccount,
				// locationForOrder: route.params.locationForOrder,
				providerSchedule: providerSchedule,
				providerServiceInterval: serviceInfo.interval,
				providerIntervalUnit: serviceInfo.intervalTimeUnit,
				availabilities: availabilitiesRoute,
				// customerID: route.params.customerID,
				providerInfo: providerInfo,
				image: serviceInfo.image,
				quotable: serviceInfo.serviceType,
				customerInfo: customerInfo,
				productID: serviceInfo.productID,
				priceID: serviceInfo.priceID,
				priceUnit: serviceInfo.priceUnit,
				priceIDArray: [
					{
						price: serviceInfo.priceID,
						name: cartItems.current.name,
					},
				],
				reviewAverage: providerQueryInfo.reviewAverage,
				numberOfReviews: providerQueryInfo.reviewList.length,
				payInStoreActive: serviceInfo.payInStoreActive,
				auth: anonymousAuth.current
			});
		} else {
			navigation.navigate("Availabilities", {
				// currName: route.params.currName,
				date: dateTemp,
				userID: providerQueryInfo.userID,
				companyName: providerQueryInfo.companyName,
				cartItems: cartItems,
				cartOptions: [
					{
						priceID: serviceInfo.priceID,
						cartItems: cartItems.current.name,
						price: cartItems.current.price,
					},
				],
				stripeConnectAccount: providerQueryInfo.stripeConnectAccount,
				locationForOrder: providerQueryInfo.streetAddress,
				providerSchedule: providerSchedule,
				providerServiceInterval: serviceInfo.interval,
				providerIntervalUnit: serviceInfo.intervalTimeUnit,
				availabilities: availabilitiesRoute,
				// customerID: route.params.customerID,
				providerInfo: providerInfo,
				image: serviceInfo.image,
				quotable: serviceInfo.serviceType,
				customerInfo: customerInfo,
				productID: serviceInfo.productID,
				priceID: serviceInfo.priceID,
				priceUnit: serviceInfo.priceUnit,
				priceIDArray: [
					{
						price: serviceInfo.priceID,
						name: cartItems.current.name,
					},
				],
				reviewAverage: providerQueryInfo.reviewAverage,
				numberOfReviews: providerQueryInfo.reviewList.length,
				payInStoreActive: serviceInfo.payInStoreActive,
				auth: anonymousAuth.current
			});
		}

		setModalVisible(false);
	};

	const getDaysInMonth = (month, year, days) => {
		let end = "";
		let pivot = moment().month(month).year(year).startOf("month");
		if (month < 10) {
			end = moment().month(month + 3).year(year).endOf("month");
		} else {
			let monthTemp = 13;
			monthTemp = monthTemp - month;
			end = moment().month(monthTemp).year(year + 1).endOf("month");
		}

		let dates = [];
		while (pivot.isBefore(end)) {
			days.forEach((day) => {
				dates.push(pivot.day(day).format("YYYY-MM-DD"));
			});
			pivot.add(1, "weeks");
		}
		return dates;
	};

	// const fetchCustomerInfo = () => {
	// 	UserAPI.fetchUserByID(auth.currentUser.uid).then((userDoc) => {
	// 		setCustomerInfo(userDoc);
	// 	});
	// };

	const fetchUserAvailabilities = async (docTemp, date) => {
		let providerSchedule = docTemp.data().openDayOrNot;
		let availabilityArrayTemp = [];
		let unavailableDaysArrayTemp = [];
		let interval = parseInt(docTemp.data().interval);
		DISABLED_DAYS = [];

		if (providerSchedule.monday.status == "CLOSED") {
			DISABLED_DAYS.push("Monday");
		}
		if (providerSchedule.tuesday.status == "CLOSED") {
			DISABLED_DAYS.push("Tuesday");
		}
		if (providerSchedule.wednesday.status == "CLOSED") {
			DISABLED_DAYS.push("Wednesday");
		}
		if (providerSchedule.thursday.status == "CLOSED") {
			DISABLED_DAYS.push("Thursday");
		}
		if (providerSchedule.friday.status == "CLOSED") {
			DISABLED_DAYS.push("Friday");
		}
		if (providerSchedule.saturday.status == "CLOSED") {
			DISABLED_DAYS.push("Saturday");
		}
		if (providerSchedule.sunday.status == "CLOSED") {
			DISABLED_DAYS.push("Sunday");
		}

		if (docTemp.data()?.vacationList != undefined) {
			setVacationList(docTemp.data()?.vacationList);
		}

		if (date == undefined) {
			const dateString = moment(date.dateString);
			const dates = getDaysInMonth(
				moment(dateString).month(),
				moment(dateString).year(),
				DISABLED_DAYS
			);
			unavailableDaysArrayTemp =
				unavailableDaysArrayTemp.concat(dates);
		} else {
			const dates = getDaysInMonth(
				moment(date.dateString).month(),
				moment(date.dateString).year(),
				DISABLED_DAYS
			);

			unavailableDaysArrayTemp =
				unavailableDaysArrayTemp.concat(dates);
		}

		if (docTemp.data()?.vacationList != undefined) {
			unavailableDaysArrayTemp = unavailableDaysArrayTemp.concat(
				docTemp.data()?.vacationList[0].data
			);
		}

		unavailableDays.current = unavailableDaysArrayTemp;

		setProviderSchedule(docTemp.data().openDayOrNot);

		const nextDays = unavailableDays.current;

		nextDays?.forEach((day) => {
			noBookingDays.current[day] = { disabled: true, disableTouchEvent: true };
		});

		setLoading(false);
	};

	// const openMaps = (streetAddress) => {
	// 	const query = encodeURIComponent(streetAddress);
	// 	const appleMapsUrl = `http://maps.apple.com/?address=${query}`;
	// 	const googleMapsUrl = `comgooglemaps://?q=${query}&center=${query}&zoom=15`;

	// 	if (Platform.OS === "ios") {
	// 		ActionSheetIOS.showActionSheetWithOptions(
	// 			{
	// 				options: [
	// 					"Cancel",
	// 					"Open in Apple Maps",
	// 					"Open in Google Maps",
	// 				],
	// 				cancelButtonIndex: 0,
	// 				destructiveButtonIndex: -1,
	// 			},
	// 			(buttonIndex) => {
	// 				switch (buttonIndex) {
	// 					case 1:
	// 						Linking.openURL(appleMapsUrl);
	// 						break;
	// 					case 2:
	// 						Linking.openURL(googleMapsUrl);
	// 						break;
	// 					default:
	// 						break;
	// 				}
	// 			}
	// 		);
	// 	} else {
	// 		Linking.openURL(googleMapsUrl);
	// 	}
	// };
	console.log(Platform.OS)
	return (
		// <Provider>
		// 	{!loading ?
		<View>
			<View style={{ flexDirection: "row", height: SCREEN_HEIGHT, overflow: "hidden" }}>
				<View style={styles.servicePageView}>
					<FlatList
						data={serviceList}
						keyExtractor={(_, index) => index.toString()}
						extraData={serviceList}
						showsVerticalScrollIndicator={false}
						ListHeaderComponent={
							providerProfilePicture != "" ? (
								<View>
									<View style={{ marginTop: SCREEN_HEIGHT * 0.05 }}>
										<View
											style={{
												flexDirection: "row",
											}}
										>
											<View>
												<Image
													style={styles.tinyLogo}
													source={{
														uri: providerProfilePicture,
													}}
												/>
											</View>
											<View>
												<Text style={styles.companyNameStyle}>
													{providerQueryInfo.companyName}
												</Text>
												{providerQueryInfo.reviewAverage != undefined ? (
													<View style={{ marginLeft: SCREEN_WIDTH * 0.02 }}>
														<View style={{ flexDirection: "row" }}>
															<Ionicons
																style={{
																	fontSize: 16,
																	color: "#36454f"
																}}
																name="star"
																type="ionicon"
															/>
															<Text
																style={{
																	fontSize: 16,
																	fontWeight: "500",
																	color: "#36454f"
																}}
															>
																{" "}{providerQueryInfo.reviewAverage} (
																{
																	providerQueryInfo.reviewList
																		?.length
																}{" "}
																ratings)
															</Text>
														</View>

														<Text
															style={{
																fontSize: 14,
																fontWeight: "500",
															}}
														>
															<Text
																style={{
																	color: "#36454f",
																	fontSize: 17,
																	fontWeight: "500",
																}}
															>
																{
																	providerQueryInfo
																		.streetAddress
																}
															</Text>
														</Text>
													</View>
												) : (
													<View
														style={{
															flexDirection: "row",
															alignItems: "center",
														}}
													>
														<Text
															style={{
																fontSize: 14,
																fontWeight: "700",
															}}
														>
															No reviews yet
														</Text>
														<Text
															style={{
																fontSize: 14,
																fontWeight: "500",
															}}
														>
															<Text
																style={{
																	color: "#36454f",
																	fontSize: 17,
																	fontWeight: "500",
																}}
															>
																{
																	providerQueryInfo
																		.streetAddress
																}
															</Text>
														</Text>
													</View>
												)}
											</View>
										</View>
									</View>

									<View
										style={{
											height: subscriptionMarginTop,
											marginBottom: SCREEN_HEIGHT * 0.05
										}}
									>
										{countOfSubscriptionsIsZero ? <></> :
											<Text style={{ fontSize: 24, fontWeight: "500", marginLeft: SCREEN_WIDTH * 0.035, marginBottom: SCREEN_HEIGHT * 0.01, color: "#36454f", marginTop: SCREEN_HEIGHT * 0.05 }}>Subscriptions</Text>
										}
										<FlatList
											data={serviceList}
											renderItem={({ item }) => (
												<TouchableOpacity
													style={{
														justifyContent:
															"center",
													}}
													onPress={() => {
														if (
															(customerCardInfo ==
																null ||
																customerCardInfo ==
																undefined) &&
															item.productID !=
															"" &&
															item.priceID != ""
														) {
															setVisible(true);
														} else {
															cartItems.current =
															{
																name: item.name,
																price: item.price,
																priceID:
																	item.priceID,
																priceUnit:
																	item.priceUnit,
															};
															setServiceInfo(
																item
															);
															setEmployeeListModal(
																true
															);
														}
													}}
												>
													{item.priceUnit ==
														"month" ||
														item.priceUnit ==
														"bi-weekly" && item.priceID == "" && item.productID == "" ? (
														<View
															style={
																styles.serviceHolderSubscription
															}
														>
															<View style={styles.serviceTitleContainer}>
																<Text
																	style={{
																		fontSize: 16,
																		fontWeight:
																			"500",
																		color: "#36454f",
																	}}
																>
																	{" " +
																		item.name +
																		" "}
																</Text>
															</View>

															<View style={{ flexDirection: "row" }}>
																<Text
																	style={
																		styles.servicePriceSubscription
																	}
																>
																	{" "}$
																	{
																		item.price
																	}
																</Text>

																{item.priceUnit ==
																	"month" ? (
																	<Text
																		style={
																			styles.serviceDescriptionSubscription
																		}
																	>
																		per{" "}
																		{
																			item.priceUnit
																		}
																	</Text>
																) : (
																	<Text
																		style={
																			styles.serviceDescriptionSubscription
																		}
																	>
																		{" "}
																		{
																			item.priceUnit
																		}
																	</Text>
																)}
																<View
																	style={
																		styles.intervalSubscription
																	}
																>
																	<Text
																		style={{
																			fontSize: 13,
																			color: "#36454f",
																			fontWeight: "500"
																		}}
																	>
																		{" "}
																		•
																		{" "}
																		{
																			item.interval
																		}{" "}
																		{
																			item.intervalTimeUnit
																		}
																		{item.interval >
																			1
																			? "s"
																			: ""}
																	</Text>
																</View>
															</View>
														</View>
													) : (
														<></>
													)}
												</TouchableOpacity>
											)}
											keyExtractor={(_, index) =>
												index.toString()
											}
										/>
									</View>
									<Text style={{ fontSize: 24, fontWeight: "500", marginLeft: SCREEN_WIDTH * 0.035, marginBottom: SCREEN_HEIGHT * 0.01, color: "#36454f" }}>Services</Text>
								</View>
							) : (
								<></>
							)
						}
						ListFooterComponent={
							<View style={{ height: SCREEN_HEIGHT * 0.03 }} />
						}
						renderItem={({ item }) => (
							<TouchableOpacity
								onPress={() => {
									if (
										(customerCardInfo == null ||
											customerCardInfo == undefined) &&
										(item.serviceType == "quotable" ||
											item.serviceType == "Not quotable")
									) {
										setVisible(true);
									} else {
										cartItems.current = {
											name: item.name,
											price: item.price,
											priceID: item.priceID,
											priceUnit: item.priceUnit,
										};
										setServiceInfo(item);
										handleNavigateOptions(
											item,
											navigation,
											route,
											cartItems,
											employeeList,
											setEmployeeListModal,
											customerInfo,
											providerSchedule,
											serviceInfo,
											providerInfo
										);
									}
								}}
							>
								{item.priceUnit != "month" &&
									item.priceUnit != "bi-weekly" && item.priceID == "" && item.productID == "" ? (
									<View style={styles.serviceHolder}>
										<View
											style={
												styles.serviceTitleContainer
											}
										>
											<Text
												style={styles.baseFontStyle}
											>
												{" " + item.name + " "}
											</Text>
										</View>

										<View style={styles.interval}>
											<Text
												style={styles.servicePrice}
											>
												{item.priceUnit == "$" ?
													" " + item.priceUnit + item.price
													: " " + "$" + item.price.toString() + item.priceUnit.split("$")[1]
												}
											</Text>
											{item.serviceType == "quotable" ? (
												<Text
													style={
														styles.requireQuote
													}
												>
													{" "}Invoice
												</Text>
											) : item.serviceType ==
												"In-Store" ? (
												<Text
													style={
														styles.requireQuote
													}
												>
													{" "}Pay In-Store
												</Text>
											) : (
												<Text
													style={
														styles.requireQuote
													}
												>
													{" "}Pay Now
												</Text>
											)}
											<Text style={styles.servicePrice}>
												{" "}•{" "}
												{item.interval} {item.intervalTimeUnit}
												{item.interval > 1 ? "s" : ""}
											</Text>
										</View>
									</View>
								) : (
									<></>
								)}
							</TouchableOpacity>
						)}
					/>

				</View>

				{Platform.OS == "web" &&
					navigator.userAgent.includes('Android') ||
					navigator.userAgent.includes('iPhone') ?
					<></>
					:
					<View style={{ width: SCREEN_WIDTH * 0.2, height: SCREEN_HEIGHT, marginTop: SCREEN_HEIGHT * 0.05, borderLeftColor: 'gray', boxShadow: '-5px 0 5px -5px rgba(0, 0, 0, 0.3)' }}>
						<View style={{ marginLeft: SCREEN_WIDTH * 0.02 }}>
							<Text style={{ marginBottom: SCREEN_HEIGHT * 0.02, fontSize: 20, fontWeight: "400" }}>Contact Information</Text>

							{providerQueryInfo.streetAddress != undefined ?
								<TextIconButtonCustom text={providerQueryInfo.streetAddress} buttonType={"location"} style={{ marginBottom: SCREEN_HEIGHT * 0.02 }} />
								:
								<></>
							}

							{providerQueryInfo.phoneNumber != undefined ?
								<TextIconButtonCustom text={providerQueryInfo.phoneNumber} buttonType={"call"} style={{ marginBottom: SCREEN_HEIGHT * 0.02 }} />
								:
								<></>
							}

							{providerQueryInfo.email != undefined ?
								<TextIconButtonCustom text={providerQueryInfo.email} buttonType={"mail"} style={{ marginBottom: SCREEN_HEIGHT * 0.02 }} />
								:
								<></>
							}
						</View>
					</View>
				}
			</View>

			<Modal visible={employeeListModal} animationType="fade" transparent>
				<View style={styles.providerModal}>
					<View>
						<IconButton
							onPress={() => {
								setEmployeeListModal(false);
							}}
							iconType="close-circle"
							customStyle={
								styles.customIconButton
							}
							customTextStyle={
								styles.customIconButtonText
							}
						/>
					</View>
					<View
						style={{
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Text
							style={{
								fontSize: 25,
								fontWeight: "600",
								marginBottom:
									SCREEN_HEIGHT * 0.07,
								color: "#36454f"
							}}
						>
							Choose Provider
						</Text>
					</View>
					<View style={{ overflow: "scroll" }}>
						<FlatList
							scrollEnabled
							data={employeeList}
							renderItem={({ item }) => (
								<View
									style={{
										// width: SCREEN_WIDTH,
										alignItems: "center",
									}}
								>
									<TextButtonWithImage
										onPress={() => {
											setProviderInfo(
												item
											);
											fetchAvailabilityAndSchedule(
												{ dateString: moment() },
												item
											);
											setEmployeeListModal(
												false
											);
											setModalVisible(
												true
											);
										}}
										text={item.firstName}
										imageSource={
											item.profilePicture
										}
										customTextStyle={{
											color: "#36454f",
										}}
										customStyle={{
											height:
												SCREEN_HEIGHT *
												0.05,
											width: SCREEN_WIDTH,
											backgroundColor:
												"white",
											borderRadius: 10,
											borderBottomColor: 'gray',
											boxShadow: '0 5px 5px -5px rgba(0, 0, 0, 0.3)',
											elevation: 5,
										}}
										customButtonColor={true}
									/>
								</View>
							)}
							keyExtractor={(_, index) =>
								index.toString()
							}
						/>
					</View>
				</View>
			</Modal>

			<Dialog
				visible={visible}
				onDismiss={hideDialog}
				style={{ zIndex: 2 }}
			>
				<Dialog.Content>
					<Text
						style={{
							fontSize: 16,
							textAlign: "left",
							color: Appearance.getColorScheme() == "light" ? "#36454f" : "white"
						}}
					>
						Please navigate to settings and set your payment
						information to proceed.
					</Text>
				</Dialog.Content>
				<Dialog.Actions>
					<Button
						color={"red"}
						labelStyle={{ fontSize: 17 }}
						onPress={() => {
							navigation.navigate("SettingsPage");
						}}
					>
						Go to settings
					</Button>
					<Button
						color={Appearance.getColorScheme() == "light" ? "#36454f" : "white"}
						labelStyle={{ fontSize: 15 }}
						onPress={() => setVisible(false)}
					>
						Not now
					</Button>
				</Dialog.Actions>
			</Dialog>
			<View>
				<Modal visible={modalVisible} animationType="fade" transparent>
					<View style={styles.calendarModal}>
						<View
							style={{
								marginBottom: 30,
								marginTop: 10,
							}}
						>
							<IconButton
								onPress={() => setModalVisible(false)}
								customStyle={styles.doneTextButton}
								customTextStyle={
									styles.doneTextButtonTextStyle
								}
								customButtonColor={true}
								iconType={"close-circle"}
							/>
						</View>
						<View
							style={{
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Text
								style={{
									fontSize: 25,
									color: "#36454f"
								}}
							>
								Availabilities
							</Text>
						</View>
						<View style={{ justifyContent: "center", marginBottom: 20 }}>
							<Calendar
								onDayPress={(day) => {
									handleCalendarOnPress(day);
								}}
								markedDates={noBookingDays.current}
								pastScrollRange={0}
								futureScrollRange={3}
								maxDate={`${moment()
									.add(2, "months")
									.format("YYYY-MM-DD")}`}
								minDate={`${new Date()}`}
							/>
						</View>
					</View>
				</Modal>
			</View>
		</View >
		// <View style={{ alignItems: "center", height: SCREEN_HEIGHT, justifyContent: "center" }}>
		// 	{Platform.OS == "ios" ?
		// 		<LottieView
		// 			source={require("../../../../assets/Loading Spinner.json")}
		// 			autoPlay
		// 			loop
		// 			style={{ height: 100 }}
		// 		/>
		// 		:
		// 		<LottieView
		// 			source={require("../../../../assets/50738-loading-line.json")}
		// 			autoPlay
		// 			loop
		// 			style={{ height: 300 }}
		// 		/>
		// 	}
		// 	<Text>Loading {route.params.companyName}'s services...</Text>
		// </View>
		// }
		// </Provider >
	);
};
