// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAjyaWePT_7OW-nVkr0M1-xoJJ2nwFWqVc",
	authDomain: "serveyou-auth.firebaseapp.com",
	projectId: "serveyou-auth",
	storageBucket: "serveyou-auth.appspot.com",
	messagingSenderId: "609700787834",
	appId: "1:609700787834:ios:ca2dc6136efebd74fd2dc3",
	measurementId: "G-34SHWZL0RW",
};


// // Initialize Firebase
// let app;

// if (firebase.apps.length === 0) {
// 	app = firebase.initializeApp(firebaseConfig);
// 	initializeAuth(app);
// } else {
// 	app = firebase.app();
// }

// const auth = firebase.auth(app);
// const db = app.firestore();



export default firebaseConfig;