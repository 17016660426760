import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { firebaseApp } from '../../index';


const db = getFirestore(firebaseApp);
;

export const fetchCartDocs = async (userID) => {
	return await db
		.collection("Cart")
		.where("user", "==", userID)
		.get()
		.then((querySnapshot) => {
			return querySnapshot;
		});
};

export const fetchCartByUser = async (userID) => {
	try {
		var cartCounter = 0;
		var stripeAccount = "";
		var retProviderId = "";
		let cancellationFeeCount = 0;
		const cartList = [];
		await db
			.collection("Cart")
			.where("user", "==", userID)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					doc.data().providerID;
					const {
						user,
						companyName,
						serviceName,
						cartOptions,
						selectedTime,
						selectedDay,
						subTotal,
						providerID,
						stripeConnectAccount,
						orderMadeAt,
						locationForOrder,
						interval,
						intervalTimeUnit,
						cancellationFee,
						cancellationFeePriceID,
						cancellationFeeProductID,
						customerID,
						providerFirstName,
					} = doc.data();
					if (cartCounter == 0) {
						retProviderId = doc.data().providerID;
					}
					cartCounter += 1;
					if (cancellationFee != undefined) {
						cancellationFeeCount += 1;
					}
					if (cancellationFee != undefined) {
						cartList.push({
							id: doc.id,
							user: user,
							providerID: providerID,
							companyName: companyName,
							serviceName: serviceName,
							cartOptions: cartOptions,
							selectedTime: selectedTime,
							selectedDay: selectedDay,
							subTotal: subTotal,
							stripeConnectAccount: stripeConnectAccount,
							locationForOrder: locationForOrder,
							interval: interval,
							intervalTimeUnit: intervalTimeUnit,
							cancellationFee: cancellationFee,
							cancellationFeePriceID: cancellationFeePriceID,
							cancellationFeeProductID: cancellationFeeProductID,
							customerID: customerID,
							providerFirstName: providerFirstName,
						});
					} else {
						cartList.push({
							id: doc.id,
							user: user,
							providerID: providerID,
							companyName: companyName,
							serviceName: serviceName,
							cartOptions: cartOptions,
							selectedTime: selectedTime,
							selectedDay: selectedDay,
							subTotal: subTotal,
							stripeConnectAccount: stripeConnectAccount,
							locationForOrder: locationForOrder,
							interval: interval,
							intervalTimeUnit: intervalTimeUnit,
							customerID: customerID,
							providerFirstName: providerFirstName,
						});
					}
					stripeAccount = stripeConnectAccount;
				});
			});

		return {
			cartList: cartList,
			providerID: retProviderId,
			cancellationFeeCount: cancellationFeeCount,
		};
	} catch (e) {
		console.log(e);
	}
};

export const fetchCartDocsByUser = async (userID) => {
	try {
		return await db
			.collection("Cart")
			.where("user", "==", userID)
			.get()
			.then((carts) => {
				return carts;
			});
	} catch (e) {
		console.log(e);
	}
};

export const deleteCartByUserID = async (userID) => {
	await fetchCartDocs(userID).then((carts) => {
		carts.forEach((cart) => {
			if (cart.data().user == userID) {
				db.collection("Cart").doc(cart.id).delete();
			}
		});
	});
};

export const deleteCartByDocID = async (docID) => {
	await db.collection("Cart").doc(docID).delete();
};
