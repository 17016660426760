import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { screenDimensions } from "../../../utils/fileWithConstants";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const TextIconButton = ({
	onPress,
	text,
	containerStyleProp,
	textStyleProp,
	buttonType,
}) => {
	const containerStyle = {
		...styles.container,
		...containerStyleProp,
	};

	const textStyle = {
		...styles.text,
		...textStyleProp,
	};
	return (
		<TouchableOpacity onPress={onPress} style={containerStyle}>
			<View
				style={{
					alignContent: "center",
					marginRight: SCREEN_WIDTH * 0.45,
				}}
			>
				<Ionicons
					style={{ color: "white", fontSize: 25 }}
					name={buttonType}
					type="ionicon"
				/>
			</View>
			<Text style={textStyle}>{text}</Text>
		</TouchableOpacity>
	);
};
const styles = StyleSheet.create({
	container: {
		width: "75%",
		borderRadius: 15,
		height: SCREEN_HEIGHT * 0.06,
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 10,
		marginTop: SCREEN_HEIGHT * 0.002,
		flexDirection: "row",
	},

	text: {
		fontWeight: "bold",
		color: "white",
		position: "absolute",
		left: SCREEN_WIDTH * 0.22,
	},
});
export default TextIconButton;
