import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { firebaseApp } from '../../index';

import { auth } from "../../index";
import { getBlockedUsers } from "./User";

const db = getFirestore(firebaseApp);
;

export const fetchChats = async (user1BlockedUsers = []) => {
	try {
		const chatList = [];
		const user = auth.currentUser.uid;
		let whichUser = "";
		let messageCountSeenUser = 0;
		let chatMessageCount = 0;
		await db
			.collection("ChatRooms")
			.where("usersArray", "array-contains", user)
			.orderBy("latestMessage", "desc")
			.get()
			.then(async (snap) => {
				for (const doc of snap.docs) {
					let user2BlockedUsers = [];
					let { user1, user2, chatID, latestMessage, text, usersArray, messageCount, messageSeenBool } =
						doc.data();
					chatMessageCount = messageCount;

					await getBlockedUsers(user2.userID).then((res) => {
						user2BlockedUsers = res;
						if (
							!user1BlockedUsers?.includes(user2.userID) &&
							!user2BlockedUsers?.includes(user1.userID)
						) {
							if (user1.userID == user && latestMessage) {
								messageCountSeenUser = user1.messagesSeen;
								chatList.push({
									companyName: user2.name,
									chatID: chatID,
									docID: doc.id,
									latestMessage:
										latestMessage !== ""
											? latestMessage.toDate()
											: new Date(),
									text: text,
									passedUser: user2.userID,
									usersArray: usersArray,
									messageCount: messageCount,
									whichUser: "user1",
									user: user1,
									messageSeenBool: user1.messageSeenBool
								});
							} else if (user2.userID == user && latestMessage) {
								messageCountSeenUser = user2.messagesSeen;
								chatList.push({
									companyName: user1.name,
									chatID: chatID,
									docID: doc.id,
									latestMessage:
										latestMessage !== ""
											? latestMessage.toDate()
											: new Date(),
									text: text,
									passedUser: user1.userID,
									usersArray: usersArray,
									messageCount: messageCount,
									whichUser: "user2",
									user: user2,
									messageSeenBool: user2.messageSeenBool
								});
							}
						}
					});
				}
			});
		return { chatList: chatList, whichUser: whichUser, messageCountSeenUser: messageCountSeenUser, chatMessageCount: chatMessageCount };
	} catch (e) {
		console.log(e);
	}
};

export const fetchLastMessageListener = async (fetch, setFetch, setChatList, chatList) => {
	const user = auth.currentUser.uid;
	const unsubscribe = db.collection("ChatRooms")
		.where("usersArray", "array-contains", user)
		.orderBy("latestMessage", "desc")
		.onSnapshot("ChatRooms", async (snap) => {
			snap.docChanges().forEach(async (change) => {

				const chatListTemp = [...chatList]; // Create a copy of chatList
				let { user1, user2, chatID, latestMessage, text, usersArray, messageCount, messageSeenBool } = change.doc.data();

				if (change.type == "modified") {
					let indexToSplice = -1;

					chatListTemp.forEach((chat, index) => {
						if (chat.usersArray?.includes(change.doc.data().user1.userID) && chat.usersArray?.includes(change.doc.data().user2.userID)) {
							indexToSplice = index;
						}
					});

					if (indexToSplice !== -1) {
						if (user1.userID == user) {
							chatListTemp[indexToSplice] = {
								companyName: user2.name,
								chatID: chatID,
								docID: change.doc.id,
								latestMessage: latestMessage !== "" ? latestMessage.toDate() : new Date(),
								text: text,
								passedUser: user2.userID,
								userArray: usersArray,
								messageCount: messageCount,
								whichUser: "user1",
								user: user1,
								messageSeenBool: user1.messageSeenBool
							};
						} else if (user2.userID == user) {
							chatListTemp[indexToSplice] = {
								companyName: user1.name,
								chatID: chatID,
								docID: change.doc.id,
								latestMessage: latestMessage !== "" ? latestMessage.toDate() : new Date(),
								text: text,
								passedUser: user2.userID,
								usersArray: usersArray,
								messageCount: messageCount,
								whichUser: "user2",
								user: user2,
								messageSeenBool: user2.messageSeenBool
							};
						}
					}
					setChatList(chatListTemp);
					setFetch(!fetch);
				}
			});
		});

	return unsubscribe
};


export const fetchChatRooms = async (chatID, user, setValue) => {
	let docID = "";
	let docTemp = "";
	await db.collection("ChatRooms")
		.where("chatID", "==", chatID) // route.params.chatID
		.get()
		.then((doc) => {
			doc.forEach((doc) => {
				if (doc.data().user1.userID == user) {
					setValue(doc.data().user1.name);
				} else if (doc.data().user2.userID == user) {
					setValue(doc.data().user2.name);
				}
				docID = doc.id;
				docTemp = doc;
			});
		});
	return { docID: docID, doc: docTemp.data() };
};

export const updateLatestMessage = async (latestMessage, text, docID) => {
	db.collection("ChatRooms").doc(docID).update({
		latestMessage: latestMessage,
		text: text,
	});
};

export const updateMessageCount = async (docID) => {
	db.collection("ChatRooms").doc(docID).update({
		messageCount: firebase.firestore.FieldValue.increment(1)
	});
};


export const updateIndividualMessageCount = async (docID, messageCount) => {
	let user = "";
	await db.collection("ChatRooms").doc(docID).get().then(async (res) => {
		if (res.data()?.user1?.userID != undefined) {
			const userID = res.data().user1.userID;

			if (auth.currentUser.uid == userID) {
				user = "user1";
			} else {
				user = "user2"
			}

			const fieldPathMessagesSeen = user + ".messagesSeen";
			const fieldPathMessageSeenBool = user + ".messageSeenBool";

			await db.collection("ChatRooms").doc(docID).update({
				[fieldPathMessagesSeen]: messageCount, [fieldPathMessageSeenBool]: true
			});
		}

	})
	return true;
};

export const updateIndividualMessageCountSender = async (docID) => {
	let user = "";
	await db.collection("ChatRooms").doc(docID).get().then(async (res) => {
		if (res.data()?.user1?.userID != undefined) {
			const userID = res.data().user1.userID;
			if (auth.currentUser.uid == userID) {
				user = "user1";
			} else {
				user = "user2"
			}

			const fieldPath = user + ".messagesSeen";
			await db.collection("ChatRooms").doc(docID).update({
				[fieldPath]: firebase.firestore.FieldValue.increment(1)
			});
		}
	})

	return true;
};

export const updateMessageSeenBoolToFalse = async (docID) => {
	let user = "";
	await db.collection("ChatRooms").doc(docID).get().then(async (res) => {
		if (res.data()?.user1?.userID != undefined) {
			const userID = res.data().user1.userID;

			if (auth.currentUser.uid != userID) {
				user = "user1";
			} else {
				user = "user2"
			}

			const fieldPathMessageSeenBool = user + ".messageSeenBool";

			await db.collection("ChatRooms").doc(docID).update({
				[fieldPathMessageSeenBool]: false
			});
		}

	})
	return true;
};

export const updateMessageSeenBoolToTrue = async (docID) => {
	let user = "";
	await db.collection("ChatRooms").doc(docID).get().then(async (res) => {
		if (res.data()?.user1?.userID != undefined) {
			const userID = res.data().user1.userID;

			if (auth.currentUser.uid == userID) {
				user = "user1";
			} else {
				user = "user2"
			}

			const fieldPathMessageSeenBool = user + ".messageSeenBool";

			await db.collection("ChatRooms").doc(docID).update({
				[fieldPathMessageSeenBool]: true
			});
		}

	})
	return true;
};