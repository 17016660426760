import React, { } from "react";
import {
	View,
	Text,
	Image,
	Pressable
} from "react-native";
import { TouchableOpacity } from "react-native-web";
// import { SliderBox } from "react-native-image-slider-box";
import { LinearGradient } from "expo-linear-gradient";
import CustomInput from "../Components/CustomInput/CustomInput";
import { IconButton } from "react-native-paper";
import { TextButton } from "../Components/CustomButtons";
import { Ionicons } from "@expo/vector-icons";

export function desktopLandingPage(showDemoForm, styles, setShowDemoForm, SCREEN_HEIGHT, SCREEN_WIDTH, setEnableScroll, firstName, setFirstName, validateName, lastName, setLastName, email, setEmail, validateEmailAddress, phoneNumber, setPhoneNumber, sendDemoFormInformation, firstNameValid, lastNameValid, emailValid) {
	return (!showDemoForm ?
		<View style={{ flex: 1, justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
			<View style={styles.hero}>
				<Text style={styles.heroText}>Your clients can book, pay, subscribe and receive invoices all in one place</Text>
				<Text style={styles.heroSubText}>
					A modern and powerful marketplace for all your service needs
				</Text>
				<TouchableOpacity onPress={() => { setShowDemoForm(true); }} style={styles.heroButton}>
					<Text style={styles.buttonText}>Get Started</Text>
				</TouchableOpacity>
			</View>
			<Image resizeMode="contain" style={{ height: SCREEN_HEIGHT * 0.7, width: SCREEN_WIDTH * 0.4 }} source={require("../../../assets/mockup.png")} />
		</View>
		:
		<LinearGradient colors={["#5252ff", "#5252ff", "white"]} style={styles.containerGradient}>
			<View
				style={{
					width: SCREEN_WIDTH,
					height: SCREEN_HEIGHT,
					alignItems: "center"
				}}
			>

				<View style={styles.demoFormContainer}>
					<Pressable onPress={() => setShowDemoForm(false)} style={{ position: "absolute", top: 10, right: 10 }}>
						<Ionicons
							style={{ fontSize: 25 }}
							name={"close-circle"}
							type="ionicon"
							color={"white"}
						/>
					</Pressable>

					<Text style={{ fontSize: 20, fontWeight: "400", position: "absolute", top: 10, color: "white" }}>Enter Your Information</Text>
					<CustomInput
						placeholder="First Name"
						value={firstName}
						setValue={(e) => {
							setFirstName(e);
							validateName(e, 0);
						}}
						inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
						validateFunction={() => validateName(firstName, 0)}
						maxLength={15}
						errorMessage={"Please provide your first name"} />
					<CustomInput
						placeholder="Last Name"
						value={lastName}
						setValue={(e) => {
							setLastName(e);
							validateName(e, 1);
						}}
						inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
						validateFunction={() => validateName(lastName, 1)}
						maxLength={15}
						errorMessage={"Please provide your last name"} />
					<CustomInput
						placeholder="Email"
						value={email}
						setValue={(e) => {
							setEmail(e);
							validateEmailAddress();
						}}
						inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
						validateFunction={validateEmailAddress}
						errorMessage={"Please enter a valid email"} />
					<CustomInput
						placeholder="Phone Number"
						value={phoneNumber}
						setValue={(e) => {
							setPhoneNumber(e);
						}}
						inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
						// validateFunction={isNumberValidationFunction(3)}
						maxLength={15} />
					<TextButton
						onPress={() => {
							sendDemoFormInformation();
						}}
						text="Book a Demo"
						customStyle={styles.heroButton}
						disableButton={firstNameValid && lastNameValid && emailValid ? false : true} />
				</View>
			</View>
		</LinearGradient>
	)
}
