import { Dimensions, Platform, StyleSheet } from "react-native";
import { screenDimensions } from "../../utils/fileWithConstants";
import { useEffect, useState } from "react";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

export const ServicePageStyles = StyleSheet.create({
	servicePageView: {
		width: SCREEN_WIDTH * 0.8,
		zIndex: 0,
	},

	ViewTextContainer: {
		backgroundColor: "white",
		height: 90,
		width: SCREEN_WIDTH,
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: 0,
		borderColor: "white",
	},

	companyNameStyle: {
		color: "#36454f",
		fontSize: 30,
		fontWeight: "500",
		marginLeft: SCREEN_WIDTH * 0.02,
	},

	serviceHolder: {
		width: Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone') ? SCREEN_WIDTH * 1 : SCREEN_WIDTH * 0.7,
		height: SCREEN_HEIGHT * 0.08,
		marginLeft: SCREEN_WIDTH * 0.035,
		borderBottomColor: 'gray',
		boxShadow: '0 5px 5px -5px rgba(0, 0, 0, 0.3)',
		elevation: 5,
		justifyContent: "center",
	},

	serviceHolderSubscription: {
		width: SCREEN_WIDTH * 0.7,
		height: SCREEN_HEIGHT * 0.08,
		marginLeft: SCREEN_WIDTH * 0.035,
		borderBottomColor: 'gray',
		boxShadow: '0 5px 5px -5px rgba(0, 0, 0, 0.3)',
		elevation: 5,
		justifyContent: "center",
	},

	tinyLogo: {
		width: SCREEN_HEIGHT * 0.2,
		height: SCREEN_HEIGHT * 0.2,
		// position: "absolute",
		borderRadius: 20,
		marginLeft: SCREEN_WIDTH * 0.035,
	},

	quote: {
		width: SCREEN_WIDTH * 0.32,
		height: SCREEN_HEIGHT * 0.05,
		position: "absolute",
		top: SCREEN_HEIGHT * 0.165,
		left: SCREEN_WIDTH * 0.68,
		borderRadius: 15,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "red"
	},
	interval: {
		flexDirection: "row",
	},
	intervalSubscription: {
		borderRadius: 15,
		flexDirection: "row",
	},
	inStore: {
	},

	serviceTitleContainer: {
		width: SCREEN_WIDTH * 0.75,
		flexDirection: "row",
		flexWrap: "wrap"
	},

	serviceTitleContainerSubscription: {
		fontWeight: "600",
		alignSelf: "center",
	},

	baseFontStyle: {
		color: "#36454f",
		fontSize: 16.5,
		fontWeight: "500",
	},

	baseFontStyleSubscription: {
		color: "",
		fontSize: 20,
	},

	servicePrice: {
		fontSize: 13,
		fontWeight: "500",
		color: "#36454f"
	},

	servicePriceSubscription: {
		fontSize: 13,
		fontWeight: "500",
		color: "#36454f"
	},

	serviceDescription: {
		fontWeight: "500",
		fontSize: 13,
		marginLeft: SCREEN_WIDTH * 0.01,
		color: "#36454f"
	},

	serviceDescriptionSubscription: {
		fontWeight: "500",
		fontSize: 13,
		color: "#36454f",
	},

	requireQuote: {
		fontSize: 13,
		fontWeight: "500",
		color: "#36454f",
	},

	modalStyle: {
		backgroundColor: "transparent",
		marginTop: SCREEN_HEIGHT < 700 ? SCREEN_HEIGHT * 0.25 : SCREEN_HEIGHT * 0.35,
	},

	containerContent: { flex: 1, marginTop: "90%" },
	headerContent: {
		marginTop: "10%",
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
	},
	sectionHeader: {
		fontSize: 23,
		fontWeight: "normal",
		marginBottom: 10,
		marginTop: 15,
		backgroundColor: "#ebedf0",
		backgroundColor: "red"
	},
	doneTextButton: {
		width: 50,
		height: 50,
		borderRadius: 100,
		backgroundColor: "transparent",
		position: "absolute",
		top: "0.1%",
		right: "5%",
	},
	doneTextButtonTextStyle: {
		fontSize: 40,
		color: "#36454f",
		fontWeight: "bold",
		alignSelf: "center",
	},
	calendarModal: {
		backgroundColor: "white",
		width: SCREEN_WIDTH,
		// height: "100%",
		position: "absolute",
		bottom: 0
	},
	providerModal: {
		height: SCREEN_HEIGHT * 0.4,
		backgroundColor: "white",
		alignItems: "center",
		position: "absolute",
		bottom: 0,
		left: 0
	},
	customIconButton: {
		// borderRadius: 100,
		// backgroundColor: "transparent",
		// position: "absolute",
		// top: SCREEN_HEIGHT * 0.01,
		// left: SCREEN_HEIGHT * 0.15,
	},
	customIconButtonText: {
		fontSize: 44,
		color: "#2f2f36",
		fontWeight: "bold",
	},
});
