import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { firebaseApp } from '../../index';

import { auth } from "../../index";
// import { calculateGeoDistance, isInRange } from "../utils/geoFunction";
import { UserAPI } from ".";
// import { getBadgeCountAsync, setBadgeCountAsync } from "expo-notifications";

const db = getFirestore(firebaseApp);
;

export const addNotification = async (data) => {
	try {
		await db
			.collection("Notifications")
			.add({
				users: [data.userID, auth.currentUser.uid],
				notificationTitle: data.notificationTitle,
				notificationContent: data.notificationContent,
				serviceName: data.serviceName,
				timeStamp: data.timeStamp,
				read: false,
				price: data.price,
				time: data.time,
				date: data.date,
				confirmationNumber: data.confirmationNumber,
			});
		return true;
	} catch (e) {
		console.log(e);
	}
};

export const deleteNotification = async (docID) => {
	try {
		await db.collection("Notifications").doc(docID).delete();
		return true;
	} catch (e) {
		console.log(e);
	}
};

export const clearAllNotifications = async () => {
	try {
		await db.collection("Notifications").where("users", "array-contains", auth.currentUser.uid).get().then((query) => {
			query.forEach(doc => {
				doc.ref.delete().then(() => {
				}).catch(error => {
					console.error("Error deleting document: ", error);
				});
			});
			updateNotificationBadge("notifications");
		});
		return true;
	} catch (e) {
		console.log(e);
	}
};

export const updateNotificationBadge = async (notificationType) => {
	let userDocID = "";
	let currentNotificationCount = 0;
	let currentBadgeCount = 0;
	let fieldPath = "";

	currentBadgeCount = await getBadgeCountAsync();

	await UserAPI.fetchUserByIDWithDocID(auth.currentUser.uid).then((docQuery) => {
		userDocID = docQuery.docID;
		currentNotificationCount = docQuery.user.notificationCount[notificationType];
	})

	currentBadgeCount = currentBadgeCount - currentNotificationCount;

	if (notificationType == "orders") {
		fieldPath = "notificationCount.orders";
		UserAPI.updateUserField(userDocID, { [fieldPath]: 0 }).catch((e) => console.log("error: ", e));
	} else if (notificationType == "notifications") {
		fieldPath = "notificationCount.notifications";
		await UserAPI.updateUserField(userDocID, { [fieldPath]: 0 }).catch((e) => console.log("error: ", e));
	}

	setBadgeCountAsync(currentBadgeCount);
}

export const fetchNotifications = async (userID) => {
	try {
		let notifications = [];
		let docID = "";
		let docTemp = {};

		await db
			.collection("Notifications")
			.where("users", "array-contains", userID)
			.orderBy("timeStamp", "desc")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					docTemp = doc.data();
					docTemp.docID = doc.id;

					notifications.push(docTemp);
				});
			});
		return { notifications: notifications };
	} catch (e) {
		console.log(e);
	}
};

//TODO: add upload user image function in ServiceRev and ServProv.js
