import { ChatRoomsAPI } from "../../API";

export const navigateToChat = async (navigation, route, chatParams) => {
	navigation.navigate("Chats", {
		userID: route.params.userID,
		name: route.params.companyName,
		chatID: chatParams.current.chatID,
		docID: chatParams.current.docID,
	});
};

export const navigateToChatFromOrders = (item, navigation) => {
	navigation.navigate("Chats", {
		userID: item.userID,
		name: item.companyName,
		chatID: chatParams.current.chatID,
		docID: chatParams.current.docID,
	});
};

export const handleNavigateOptions = (
	item,
	navigation,
	route,
	cartItems,
	employeeList,
	setEmployeeListModal,
	customerInfo,
	providerSchedule,
	serviceInfo,
	providerInfo
) => {
	if (item.optionsAvailable == true) {
		if (route.params.mobileLocation) {
			navigation.navigate("Options", {
				item: item,
				userID: route.params.userID,
				stripeConnectAccount: route.params.stripeConnectAccount,
				cartItems: cartItems,
				companyName: route.params.companyName,
				serviceName: item.name,
				quotable: item.serviceType,
				priceID: item.priceID,
				productID: item.productID,
				priceUnit: item.priceUnit,
				locationForOrder: route.params.locationForOrder,
				providerID: route.params.userID,
				cancellationFee: item.cancellationFee,
				cancellationFeePriceID: item.cancellationFeePriceID,
				cancellationFeeProductID: item.cancellationFeeProductID,
				image: item.image,
				reviewAverage: route.params.reviewAverage,
				numberOfReviews: route.params.numberOfReviews,
				customerInfo: customerInfo,
				employeeList: employeeList,
				serviceID: item.id,
				providerInfo: providerInfo,
				providerSchedule: providerSchedule,
				providerServiceInterval: serviceInfo.interval,
				providerIntervalUnit: serviceInfo.intervalTimeUnit,
			});
		} else {
			navigation.navigate("Options", {
				item: item,
				userID: route.params.userID,
				stripeConnectAccount: route.params.stripeConnectAccount,
				cartItems: cartItems,
				companyName: route.params.companyName,
				serviceName: item.name,
				quotable: item.serviceType,
				priceID: item.priceID,
				productID: item.productID,
				priceUnit: item.priceUnit,
				locationForOrder: route.params.streetAddress,
				providerID: route.params.userID,
				cancellationFee: item.cancellationFee,
				cancellationFeePriceID: item.cancellationFeePriceID,
				cancellationFeeProductID: item.cancellationFeeProductID,
				image: item.image,
				reviewAverage: route.params.reviewAverage,
				numberOfReviews: route.params.numberOfReviews,
				customerInfo: customerInfo,
				employeeList: employeeList,
				serviceID: item.id,
				providerInfo: providerInfo,
				providerSchedule: providerSchedule,
				providerServiceInterval: serviceInfo.interval,
				providerIntervalUnit: serviceInfo.intervalTimeUnit,
			});
		}
	} else if (
		item.optionsAvailable == false ||
		item.optionsAvailable == undefined
	) {
		setEmployeeListModal(true);
	}
};
