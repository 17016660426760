import React, { useContext, useState, useEffect, useRef } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	FlatList,
	Image,
	Dimensions,
	NativeModules,
	Platform,
	Modal,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
	TextButton,
	IconButton,
	TextIconButton,
} from "../../Components/CustomButtons";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import SwipeUpDownModal from "react-native-swipe-modal-up-down";
import { useNavigation } from "@react-navigation/native";
import { daysOfTheWeek, screenDimensions } from "../../../utils/fileWithConstants";
import { UserAPI } from "../../../API";
import {
	connectFunctionsEmulator,
	getFunctions,
	httpsCallable,
} from "firebase/functions";
import DynamicTextIconButton from "../../Components/CustomButtons/DynamicTextIconButton";
import { Ionicons } from "@expo/vector-icons";
import uuid from "react-native-uuid";
import moment from "moment";
import { Snackbar } from "react-native-paper";
import { FieldValue, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { auth } from "../../../../index"
import CustomInput from "../../../shared/Components/CustomInput/CustomInput";
import LottieView from "lottie-react-native";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const Availabilities = ({ route }) => {
	const [scheduleList, setScheduleList] = useState("");
	const tax = useRef(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [animateModal, setanimateModal] = useState(true);
	const [priceSum, setPriceSum] = useState(0);
	const [selectedTime, setSelectedTime] = useState("");
	const navigation = useNavigation();
	const [customerID, setCustomerID] = useState("");
	const [nthOccurrenceOfDay, setNThOccurrenceOfDay] = useState("");
	const secondNthOccurrenceOfDay = useRef("");
	const [loadingSpinner, setLoadingSpinner] = useState(false);
	const [loading, setLoading] = useState(false);
	const subscribeButtonProperties = {
		true: { position: "absolute", left: 30 },
		false: { alignSelf: "center" },
	};
	const [docID, setDocID] = useState("");
	const eventID = useRef("");
	const [helperMessageVisible, setHelperMessageVisible] = useState("");
	const [helperMessageVisible1, setHelperMessageVisible1] = useState("");
	const [providerDoc, setProviderDoc] = useState("");
	const [bookingQueue, setBookingQueue] = useState([]);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [emailValid, setEmailValid] = useState(false);
	const [firstNameValid, setFirstNameValid] = useState(false);
	const [lastNameValid, setLastNameValid] = useState(false);
	const [SCREEN_HEIGHT, setSCREEN_HEIGHT] = useState(Dimensions.get('window').height);
	const [SCREEN_WIDTH, setSCREEN_WIDTH] = useState(Dimensions.get('window').width);
	const [enableScroll, setEnableScroll] = useState("scroll");

	const styles = {
		containerHeader: {
			flex: 1,
			alignContent: "center",
			alignItems: "center",
			justifyContent: "center",
			height: 5,
			width: 90,
			backgroundColor: "gray",
			marginTop: Dimensions.get("window").height * 0.3,
			borderRadius: 50,
		},
		headerContent: {
			marginTop: SCREEN_HEIGHT * 0.07,
			alignContent: "center",
			alignItems: "center",
			justifyContent: "center",
		},
		modalStyles: {
			backgroundColor: "white",
			marginTop: Dimensions.get("window").height * 0.1,
		},
		modalButtons: {
			// position: "absolute",
			// bottom: SCREEN_HEIGHT * 0.1
		},

		tinyLogo: {
			width: SCREEN_WIDTH * 0.1,
			height: SCREEN_WIDTH * 0.1,
			borderRadius: 10,
			marginLeft: SCREEN_WIDTH * 0.03
		},
	}

	useEffect(() => {
		fetchAvailability();
		initializeNthOccurrenceOfTheMonth();

		const handleResize = () => {
			if (Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone')) {
				setSCREEN_HEIGHT(Dimensions.get('screen').height);
				setSCREEN_WIDTH(Dimensions.get('screen').width);
			} else {
				setSCREEN_HEIGHT(Dimensions.get('window').height);
				setSCREEN_WIDTH(Dimensions.get('window').width);
			}
		};

		//disable resize for web on mobile
		if (Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone')) {
			null;
		} else {
			Dimensions.addEventListener('change', handleResize);
		}

		// Clean up the event listener when the component unmounts
		return () => {
			Dimensions.removeEventListener('change', handleResize);
		};
	}, []);

	const initializeNthOccurrenceOfTheMonth = () => {
		const selectedDate = new Date(route.params.date);
		let selectedDay = selectedDate.getUTCDate();
		let found = false;
		let countDayOfTheWeekOccurrence = 0;
		while (selectedDay > 0) {
			selectedDay = selectedDay - 7;
			if (selectedDay == 0 && countDayOfTheWeekOccurrence == 0) {
				found = true;
			} else {
				countDayOfTheWeekOccurrence += 1;
			}
		}
		if (found == true) {
			countDayOfTheWeekOccurrence = 1;
		} else if (countDayOfTheWeekOccurrence == 5) {
			countDayOfTheWeekOccurrence -= 1;
		}
		setNThOccurrenceOfDay(countDayOfTheWeekOccurrence);
	};

	const fetchAvailability = async () => {
		const providerDoc = await UserAPI.fetchUserByIDWithDocID(route.params.providerInfo.userID);
		setProviderDoc(providerDoc);
		setLoading(true);
		const data = {
			route: route,
		};
		const functions = getFunctions();

		const response = await httpsCallable(
			functions,
			"handleAvailability"
		)(data)
			.then(async function (result) {
				setScheduleList(result.data.list);
				setLoading(false);
			})
			.catch((e) => console.log(e.message));

		// const functions = getFunctions();
		// const stripeWebTest = async () => {
		// 	const options = {
		// 		method: 'POST', // Specify the HTTP method
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		body: data, // Convert the data to a JSON string
		// 	};
		// 	fetch('https://us-central1-serveyou-auth.cloudfunctions.net/handleAvailabilityWeb', options)
		// 		.then((result) => {
		// 			setScheduleList(result.data.list);
		// 			setLoading(false);
		// 		})
		// 		.catch(error => console.error(error));
		// 	return response;
		// };
	};

	// useEffect(() => {
	// 	let selectedDate = new Date(route.params.date);
	// 	const list = [];
	// 	UserAPI.fetchUsers().then((queryUser) => {
	// 		queryUser.forEach((doc) => {
	// 			if (doc.data().userID == route.params.providerInfo.userID) {
	// 				list.push({
	// 					startTime: doc.data().startTime,
	// 					closingTime: doc.data().closingTime,
	// 					interval: doc.data().interval,
	// 					jobDates: doc.data().jobDates,
	// 				});
	// 			}
	// 		});
	// 	});

	// 	UserAPI.fetchUsers().then((queryUser) => {
	// 		queryUser.forEach((doc) => {
	// 			if (doc.data().userID == auth.currentUser.uid) {
	// 				setCustomerID(doc.data().customerID);
	// 				list.push({
	// 					customerID: doc.data().customerID,
	// 				});
	// 			}
	// 		});
	// 	});
	// }, []);

	useEffect(() => {
		if (
			route.params.priceUnit == "month" ||
			route.params.priceUnit == "bi-weekly"
		) {
			setPriceSum(
				Math.round(route.params.cartItems?.current.price * 100) / 100
			);
			calculateTax(
				Math.round(route.params.cartItems?.current.price * 100) / 100
			);
		} else {
			let optionPriceSum = 0;
			if (route.params.cartOptions.length == 1) {
				optionPriceSum = Number(route.params.cartItems?.current.price);
			} else {
				route.params.cartOptions?.forEach(
					(e) => (optionPriceSum = optionPriceSum + Number(e.price))
				);
				optionPriceSum =
					Number(route.params.cartItems?.current.price) +
					optionPriceSum;
			}
			setPriceSum(Math.round(optionPriceSum * 100) / 100);
			calculateTax(Math.round(optionPriceSum * 100) / 100);
		}
	}, []);

	function getWeekOfTheMonth(tempDate) {
		const beginningOfMonth = moment(tempDate).startOf("month");
		const selectedDay = moment(tempDate);
		let diffInDays = selectedDay.diff(beginningOfMonth, "day");
		let diffInWeeks = Math.floor(diffInDays / 7);
		if (diffInWeeks == 5) {
			diffInWeeks = 4;
		}
		return diffInWeeks;
	}

	const calculateTax = (priceSum) => {
		const user = auth.currentUser.uid;
		const cartList = [
			{
				cartOptions: route.params.cartOptions,
				companyName: route.params.companyName,
				customerID: customerID,
				interval: route.params.providerServiceInterval,
				intervalTimeUnit: route.params.providerIntervalUnit,
				locationForOrder: route.params.locationForOrder,
				selectedDay: route.params.date,
				selectedTime: selectedTime,
				serviceName: route.params.cartItems,
				stripeConnectAccount: route.params.stripeConnectAccount,
				subTotal: priceSum,
				user: user,
			},
		];
		if (
			route.params.priceUnit == "month" ||
			route.params.priceUnit == "bi-weekly"
		) {
			let taxTemp = "";
			let total = "";
			if (
				route.params.locationForOrder.includes("Québec") ||
				route.params.locationForOrder.includes("Gatineau") ||
				route.params.locationForOrder.includes("Hull") ||
				route.params.locationForOrder.includes("QC")
			) {
				taxTemp =
					Math.round(
						(cartList[0].subTotal * 0.14975 + Number.EPSILON) * 100
					) / 100;
				total = cartList[0].subTotal + taxTemp;
			} else if (
				route.params.locationForOrder.includes("Ontario") ||
				route.params.locationForOrder.includes("Ottawa") ||
				route.params.locationForOrder.includes("ON")
			) {
				taxTemp =
					Math.round(
						(cartList[0].subTotal * 0.13 + Number.EPSILON) * 100
					) / 100;
				total = cartList[0].subTotal + taxTemp;
			}
			tax.current = taxTemp;
		} else {
			let taxTemp = "";
			let total = "";
			if (
				route.params.locationForOrder.includes("Québec") ||
				route.params.locationForOrder.includes("Gatineau") ||
				route.params.locationForOrder.includes("Hull") ||
				route.params.locationForOrder.includes("QC")
			) {
				taxTemp =
					Math.round(
						(cartList[0].subTotal * 0.14975 + Number.EPSILON) * 100
					) / 100;
				total = cartList[0].subTotal + taxTemp;
			} else if (
				route.params.locationForOrder.includes("Ontario") ||
				route.params.locationForOrder.includes("Ottawa") ||
				route.params.locationForOrder.includes("ON")
			) {
				taxTemp =
					Math.round(
						(cartList[0].subTotal * 0.13 + Number.EPSILON) * 100
					) / 100;
				total = cartList[0].subTotal + taxTemp;
			}
			tax.current = taxTemp;
		}
	};

	const addToCart = async () => {
		setLoading(true);
		let currentDateAndTime = new Date();
		let cartCounter = 0;
		const db = firebase.firestore();
		const user = auth.currentUser.uid;
		if (route.params.providerInfo.userID == route.params.providerInfo.userID) {
			if (
				route.params.cancellationFeeProductID != undefined &&
				route.params.cancellationFee != undefined &&
				route.params.cancellationFeePriceID != undefined
			) {
				updateAvailabilityCart(route.params.date, selectedTime).then(async (result) => {
					if (result == true) {
						await db
							.collection("Cart")
							.add({
								user: user,
								subTotal: priceSum,
								companyName: route.params.companyName,
								serviceName: route.params?.cartItems,
								priceUnit: route.params.priceUnit,
								cartOptions: route.params.cartOptions,
								selectedTime: selectedTime,
								selectedDay: route.params.date,
								customerID: customerID,
								providerID: route.params.providerInfo.userID,
								intervalTimeUnit: route.params.providerIntervalUnit,
								interval: route.params.providerServiceInterval,
								stripeConnectAccount: route.params.stripeConnectAccount,
								locationForOrder: route.params.locationForOrder,
								orderPlacedAt: currentDateAndTime.toLocaleString(),
								cancellationFee: route.params.cancellationFee,
								cancellationFeePriceID:
									route.params.cancellationFeePriceID,
								cancellationFeeProductID:
									route.params.cancellationFeeProductID,
								customerFirstName: firstName,
								customerFirstName: lastName,
								providerFirstName: route.params.providerInfo.firstName,
								providerLastName: route.params.providerInfo.lastName,
								providerProfilePicture:
									route.params.providerInfo.profilePicture,
							})
							.then(() => { })
							.catch((e) => console.log(e));
						navigation.navigate("Cart", {
							route: route,
						});
					} else if (result == "concurrency issue") {
						setLoading(false);
						setHelperMessageVisible1(true);
					}
					else if (result == false) {
						setLoading(false);
						setHelperMessageVisible(true);
					}
				});
			} else {
				updateAvailabilityCart(route.params.date, selectedTime).then(async (result) => {
					if (result == true) {
						await db
							.collection("Cart")
							.add({
								user: user,
								subTotal: priceSum,
								companyName: route.params.companyName,
								serviceName: route.params?.cartItems,
								priceUnit: route.params.priceUnit,
								cartOptions: route.params.cartOptions,
								selectedTime: selectedTime,
								selectedDay: route.params.date,
								customerID: customerID,
								providerID: route.params.providerInfo.userID,
								intervalTimeUnit: route.params.providerIntervalUnit,
								interval: route.params.providerServiceInterval,
								stripeConnectAccount: route.params.stripeConnectAccount,
								locationForOrder: route.params.locationForOrder,
								orderPlacedAt: currentDateAndTime.toLocaleString(),
								customerFirstName: firstName,
								customerFirstName: lastName,
								providerFirstName: route.params.providerInfo.firstName,
								providerLastName: route.params.providerInfo.lastName,
								providerProfilePicture:
									route.params.providerInfo.profilePicture,
							})
							.then(() => { })
							.catch((e) => console.log(e));
						navigation.navigate("Cart", {
							route: route,
						});
					} else if (result == "concurrency issue") {
						setLoading(false);
						setHelperMessageVisible1(true);
					}
					else if (result == false) {
						setLoading(false);
						setHelperMessageVisible(true);
					}
				});
			}

		} else {
			if (
				route.params.cancellationFeeProductID != undefined &&
				route.params.cancellationFee != undefined &&
				route.params.cancellationFeePriceID != undefined
			) {
				updateAvailabilityCart(route.params.date, selectedTime).then(async (result) => {
					if (result == true) {
						await db
							.collection("Cart")
							.add({
								user: user,
								subTotal: priceSum,
								companyName: route.params.companyName,
								serviceName: route.params?.cartItems,
								priceUnit: route.params.priceUnit,
								cartOptions: route.params.cartOptions,
								selectedTime: selectedTime,
								selectedDay: route.params.date,
								customerID: customerID,
								providerName: route.params.providerInfo.firstName,
								providerID: route.params.providerInfo.userID,
								intervalTimeUnit: route.params.providerIntervalUnit,
								interval: route.params.providerServiceInterval,
								stripeConnectAccount: route.params.stripeConnectAccount,
								locationForOrder: route.params.locationForOrder,
								orderPlacedAt: currentDateAndTime.toLocaleString(),
								cancellationFee: route.params.cancellationFee,
								cancellationFeePriceID:
									route.params.cancellationFeePriceID,
								cancellationFeeProductID:
									route.params.cancellationFeeProductID,
								customerFirstName: firstName,
								customerFirstName: lastName,
								providerFirstName: route.params.providerInfo.firstName,
								providerLastName: route.params.providerInfo.lastName,
								providerProfilePicture:
									route.params.providerInfo.profilePicture,
							})
							.then(() => {
								navigation.navigate("Cart", {
									route: route,
								});
							})
							.catch((e) => console.log(e));
					} else if (result == "concurrency issue") {
						setLoading(false);
						setHelperMessageVisible1(true);
					}
					else if (result == false) {
						setLoading(false);
						setHelperMessageVisible(true);
					}
				});
			} else {
				updateAvailabilityCart(route.params.date, selectedTime).then(async (result) => {
					if (result == true) {
						await db
							.collection("Cart")
							.add({
								user: user,
								subTotal: priceSum,
								companyName: route.params.companyName,
								serviceName: route.params?.cartItems,
								priceUnit: route.params.priceUnit,
								cartOptions: route.params.cartOptions,
								selectedTime: selectedTime,
								selectedDay: route.params.date,
								customerID: customerID,
								providerID: route.params.providerInfo.userID,
								intervalTimeUnit: route.params.providerIntervalUnit,
								interval: route.params.providerServiceInterval,
								stripeConnectAccount: route.params.stripeConnectAccount,
								locationForOrder: route.params.locationForOrder,
								orderPlacedAt: currentDateAndTime.toLocaleString(),
								customerFirstName: firstName,
								customerFirstName: lastName,
								providerFirstName: route.params.providerInfo.firstName,
								providerLastName: route.params.providerInfo.lastName,
								providerProfilePicture:
									route.params.providerInfo.profilePicture,
							})
							.then(() => {
								navigation.navigate("Cart", {
									route: route,
								});
							})
							.catch((e) => console.log(e));
					} else if (result == "concurrency issue") {
						setLoading(false);
						setHelperMessageVisible1(true);
					}
					else if (result == false) {
						setLoading(false);
						setHelperMessageVisible(true);
					}
				});
			}
		}
	};

	const addToOrders = async () => {
		setLoading(true);
		const confirmationNumber = uuid.v4().slice(29).toUpperCase();
		let cartCounter = 0;
		const db = firebase.firestore();
		const user = auth.currentUser.uid;
		let emailTemp = "";

		if (auth.currentUser.email == null && auth.currentUser.isAnonymous == false) {
			await UserAPI.fetchUserByID(auth.currentUser.uid).then(
				(userDoc) => {
					emailTemp = userDoc.email;
				}
			);
		}

		if (route.params.cancellationFee != undefined) {
			if (route.params.providerInfo.userID == route.params.providerInfo.userID) {
				updateAvailability(route.params.date, selectedTime).then(async (result) => {
					if (result == true) {
						await db
							.collection("Orders")
							.add({
								user: user,
								subTotal: priceSum,
								companyName: route.params.companyName,
								serviceName: route.params.cartItems,
								productID: route.params.productID,
								priceID: route.params.priceID,
								priceIDArray: route.params.priceIDArray,
								priceUnit: route.params.priceUnit,
								customerID: customerID,
								cartOptions: route.params.cartOptions,
								selectedTime: selectedTime,
								selectedDay: route.params.date,
								providerID: route.params.providerInfo.userID,
								quotable: route.params.quotable,
								stripeConnectAccount: route.params.stripeConnectAccount,
								customerFirstName: firstName,
								customerLastName: lastName,
								providerFirstName: route.params.providerInfo.firstName,
								providerLastName: route.params.providerInfo.lastName,
								providerProfilePicture:
									route.params.providerInfo.profilePicture,
								intervalTimeUnit: route.params.providerIntervalUnit,
								interval: route.params.providerServiceInterval,
								locationForOrder: route.params.locationForOrder,
								confirmationNumber: confirmationNumber,
								cancellationFee: route.params.cancellationFee,
								cancellationFeePriceID:
									route.params.cancellationFeePriceID,
								cancellationFeeProductID:
									route.params.cancellationFeeProductID,
								emailReminder: false,
								customerEmail: email
							})
							.then(() => {
							});
						const cartList = [
							{
								cartOptions: route.params.cartOptions,
								companyName: route.params.companyName,
								customerID: customerID,
								interval: route.params.providerServiceInterval,
								intervalTimeUnit: route.params.providerIntervalUnit,
								locationForOrder: route.params.locationForOrder,
								selectedDay: route.params.date,
								selectedTime: selectedTime,
								serviceName: route.params.cartItems,
								stripeConnectAccount: route.params.stripeConnectAccount,
								subTotal: priceSum,
								user: user,
							},
						];
						if (route.params.cartOptions.length == 1) {
							sendEmailSubscriptionOrderConfirmation(
								confirmationNumber,
								cartList,
								email
							);
						} else {
							sendEmailOrderConfirmation(
								confirmationNumber,
								cartList,
								email
							);
						}
						navigation.navigate("ServicePage", { appointmentBooked: true });
					} else if (result == "concurrency issue") {
						setLoading(false);
						setHelperMessageVisible1(true);
					}
					else if (result == false) {
						setLoading(false);
						setHelperMessageVisible(true);
					}
				});
			} else {
				updateAvailability(route.params.date, selectedTime).then(async (result) => {
					if (result == true) {
						await db.collection("Orders").add({
							user: user,
							subTotal: priceSum,
							companyName: route.params.companyName,
							serviceName: route.params.cartItems,
							productID: route.params.productID,
							priceID: route.params.priceID,
							priceIDArray: route.params.priceIDArray,
							priceUnit: route.params.priceUnit,
							customerID: customerID,
							cartOptions: route.params.cartOptions,
							selectedTime: selectedTime,
							selectedDay: route.params.date,
							providerID: route.params.providerInfo.userID,
							quotable: route.params.quotable,
							stripeConnectAccount: route.params.stripeConnectAccount,
							customerFirstName: firstName,
							customerLastName: lastName,
							providerFirstName: route.params.providerInfo.firstName,
							providerLastName: route.params.providerInfo.lastName,
							providerProfilePicture:
								route.params.providerInfo.profilePicture,
							intervalTimeUnit: route.params.providerIntervalUnit,
							interval: route.params.providerServiceInterval,
							locationForOrder: route.params.locationForOrder,
							confirmationNumber: confirmationNumber,
							cancellationFee: route.params.cancellationFee,
							cancellationFeePriceID: route.params.cancellationFeePriceID,
							cancellationFeeProductID:
								route.params.cancellationFeeProductID,
							emailReminder: false,
							customerEmail: email
						});
						const cartList = [
							{
								cartOptions: route.params.cartOptions,
								companyName: route.params.companyName,
								customerID: customerID,
								interval: route.params.providerServiceInterval,
								intervalTimeUnit: route.params.providerIntervalUnit,
								locationForOrder: route.params.locationForOrder,
								selectedDay: route.params.date,
								selectedTime: selectedTime,
								serviceName: route.params.cartItems,
								stripeConnectAccount: route.params.stripeConnectAccount,
								subTotal: priceSum,
								priceUnit: route.params.priceUnit,
								user: user,
							},
						];
						if (route.params.cartOptions.length == 1) {
							sendEmailSubscriptionOrderConfirmation(
								confirmationNumber,
								cartList,
								email
							);
						} else {
							sendEmailOrderConfirmation(
								confirmationNumber,
								cartList,
								email
							);
						}
						navigation.navigate("ServicePage", { appointmentBooked: true })
					}
					else if (result == "concurrency issue") {
						setLoading(false);
						setHelperMessageVisible1(true);
					}
					else if (result == false) {
						setLoading(false);
						setHelperMessageVisible(true);
					}
				})
			}
		} else {
			if (route.params.providerInfo.userID == route.params.providerInfo.userID) {
				if (route.params.payInStoreActive == true) {
					updateAvailability(route.params.date, selectedTime).then(async (result) => {
						if (result == true) {
							await db
								.collection("Orders")
								.add({
									user: user,
									subTotal: priceSum,
									companyName: route.params.companyName,
									serviceName: route.params.cartItems,
									productID: route.params.productID,
									priceID: route.params.priceID,
									priceIDArray: route.params.priceIDArray,
									priceUnit: route.params.priceUnit,
									cartOptions: route.params.cartOptions,
									selectedTime: selectedTime,
									selectedDay: route.params.date,
									providerID: route.params.providerInfo.userID,
									quotable: route.params.quotable,
									stripeConnectAccount:
										route.params.stripeConnectAccount,
									customerFirstName:
										firstName,
									customerLastName:
										lastName,
									providerFirstName:
										route.params.providerInfo.firstName,
									providerLastName:
										route.params.providerInfo.lastName,
									providerProfilePicture:
										route.params.providerInfo.profilePicture,
									intervalTimeUnit: route.params.providerIntervalUnit,
									interval: route.params.providerServiceInterval,
									locationForOrder: route.params.locationForOrder,
									confirmationNumber: confirmationNumber,
									emailReminder: false,
									customerEmail: email
								})
								.then(() => { });
							const cartList = [
								{
									cartOptions: route.params.cartOptions,
									companyName: route.params.companyName,
									interval: route.params.providerServiceInterval,
									intervalTimeUnit: route.params.providerIntervalUnit,
									locationForOrder: route.params.locationForOrder,
									selectedDay: route.params.date,
									selectedTime: selectedTime,
									serviceName: route.params.cartItems,
									stripeConnectAccount:
										route.params.stripeConnectAccount,
									subTotal: priceSum,
									user: user,
								},
							];
							if (route.params.cartOptions.length == 1) {
								sendEmailSubscriptionOrderConfirmation(
									confirmationNumber,
									cartList,
									email
								);
							} else {
								sendEmailOrderConfirmation(
									confirmationNumber,
									cartList,
									email
								);
							}
							navigation.navigate("ServicePage", { appointmentBooked: true });
						} else if (result == "concurrency issue") {

							setLoading(false);
							setHelperMessageVisible1(true);
						}
						else if (result == false) {
							setLoading(false);
							setHelperMessageVisible(true);
						}
					})
				} else {
					updateAvailability(route.params.date, selectedTime).then(async (result) => {
						if (result == true) {
							await db
								.collection("Orders")
								.add({
									user: user,
									subTotal: priceSum,
									companyName: route.params.companyName,
									serviceName: route.params.cartItems,
									productID: route.params.productID,
									priceID: route.params.priceID,
									priceIDArray: route.params.priceIDArray,
									priceUnit: route.params.priceUnit,
									customerID: customerID,
									cartOptions: route.params.cartOptions,
									selectedTime: selectedTime,
									selectedDay: route.params.date,
									providerID: route.params.providerInfo.userID,
									quotable: route.params.quotable,
									stripeConnectAccount:
										route.params.stripeConnectAccount,
									customerFirstName:
										firstName,
									customerLastName:
										lastName,
									providerFirstName:
										route.params.providerInfo.firstName,
									providerLastName:
										route.params.providerInfo.lastName,
									providerProfilePicture:
										route.params.providerInfo.profilePicture,
									intervalTimeUnit: route.params.providerIntervalUnit,
									interval: route.params.providerServiceInterval,
									locationForOrder: route.params.locationForOrder,
									confirmationNumber: confirmationNumber,
									emailReminder: false,
									customerEmail: email
								})
								.then(() => { });
							const cartList = [
								{
									cartOptions: route.params.cartOptions,
									companyName: route.params.companyName,
									customerID: customerID,
									interval: route.params.providerServiceInterval,
									intervalTimeUnit: route.params.providerIntervalUnit,
									locationForOrder: route.params.locationForOrder,
									selectedDay: route.params.date,
									selectedTime: selectedTime,
									serviceName: route.params.cartItems,
									stripeConnectAccount:
										route.params.stripeConnectAccount,
									subTotal: priceSum,
									user: user,
								},
							];
							if (route.params.cartOptions.length == 1) {
								sendEmailSubscriptionOrderConfirmation(
									confirmationNumber,
									cartList,
									email
								);
							} else {
								sendEmailOrderConfirmation(
									confirmationNumber,
									cartList,
									email
								);
							}
							navigation.navigate("ServicePage", { appointmentBooked: true });
						} else if (result == "concurrency issue") {
							setLoading(false);
							setHelperMessageVisible1(true);
						}
						else if (result == false) {
							setLoading(false);
							setHelperMessageVisible(true);
						}
					})
				}
			} else {
				updateAvailability(route.params.date, selectedTime).then(async (result) => {
					if (result == true) {
						await db.collection("Orders").add({
							user: user,
							subTotal: priceSum,
							companyName: route.params.companyName,
							serviceName: route.params.cartItems,
							productID: route.params.productID,
							priceID: route.params.priceID,
							priceIDArray: route.params.priceIDArray,
							priceUnit: route.params.priceUnit,
							customerID: customerID,
							cartOptions: route.params.cartOptions,
							selectedTime: selectedTime,
							selectedDay: route.params.date,
							providerID: route.params.providerInfo.userID,
							quotable: route.params.quotable,
							stripeConnectAccount: route.params.stripeConnectAccount,
							customerFirstName: firstName,
							customerLastName: lastName,
							providerFirstName: route.params.providerInfo.firstName,
							providerLastName: route.params.providerInfo.lastName,
							providerProfilePicture:
								route.params.providerInfo.profilePicture,
							intervalTimeUnit: route.params.providerIntervalUnit,
							interval: route.params.providerServiceInterval,
							locationForOrder: route.params.locationForOrder,
							confirmationNumber: confirmationNumber,
							emailReminder: false,
							customerEmail: email
						});
						const cartList = [
							{
								cartOptions: route.params.cartOptions,
								companyName: route.params.companyName,
								customerID: customerID,
								interval: route.params.providerServiceInterval,
								intervalTimeUnit: route.params.providerIntervalUnit,
								locationForOrder: route.params.locationForOrder,
								selectedDay: route.params.date,
								selectedTime: selectedTime,
								serviceName: route.params.cartItems,
								stripeConnectAccount: route.params.stripeConnectAccount,
								subTotal: priceSum,
								priceUnit: route.params.priceUnit,
								user: user,
							},
						];
						if (route.params.cartOptions.length == 1) {
							sendEmailSubscriptionOrderConfirmation(
								confirmationNumber,
								cartList,
								email
							);
						} else {
							sendEmailOrderConfirmation(
								confirmationNumber,
								cartList,
								email
							);
						}
						navigation.navigate("ServicePage", { appointmentBooked: true });
					} else if (result == "concurrency issue") {
						setLoading(false);
						setHelperMessageVisible1(true);
					}
					else if (result == false) {
						setLoading(false);
						setHelperMessageVisible(true);
					}

				})
			}
		}
	};

	const handleSubscription = async (priceID) => {
		setLoading(true);

		const confirmationNumber = uuid.v4().slice(29).toUpperCase();

		if (route.params.payInStoreActive == undefined) {
			const cartList = [
				{
					cartOptions: route.params.cartOptions,
					companyName: route.params.companyName,
					customerID: customerID,
					interval: route.params.providerServiceInterval,
					intervalTimeUnit: route.params.providerIntervalUnit,
					locationForOrder: route.params.locationForOrder,
					selectedDay: route.params.date,
					selectedTime: selectedTime,
					serviceName: route.params.cartItems,
					stripeConnectAccount: route.params.stripeConnectAccount,
					subTotal: priceSum,
					user: auth.currentUser.uid,
				},
			];

			const provinceCodes = {
				Alberta: "AB",
				"British Columbia": "BC",
				Manitoba: "MB",
				"New Brunswick": "NB",
				"Newfoundland and Labrador": "NL",
				"Northwest Territories": "NT",
				"Nova Scotia": "NS",
				Nunavut: "NU",
				Ontario: "ON",
				"Prince Edward Island": "PE",
				Québec: "QC",
				Saskatchewan: "SK",
				Yukon: "YT",
			};

			const location = route.params.locationForOrder;
			let province = ""; // Default to Ontario if province code not found

			for (const provinceName in provinceCodes) {
				if (location.includes(provinceName)) {
					province = provinceCodes[provinceName];
					break;
				}
			}

			let selectedDate = new Date(route.params.date);
			const dayOfTheMonth = selectedDate.getDate();
			const monthOfTheYear = selectedDate.getMonth();
			const weekOfTheMonth = nthOccurrenceOfDay;
			const dayOfTheWeek = daysOfTheWeek[selectedDate.getUTCDay()];

			updateAvailabilitySubscription(route.params.date, selectedTime).then(async (result) => {
				if (result == true) {
					const data = {
						customer: route.params.customerID,
						price: priceID,
						province: province,
						date: route.params.date,
						subscriptionType: route.params.cartItems.current.priceUnit,
					};
					const functions = getFunctions();
					const response = await httpsCallable(
						functions,
						"subscriptionAccept"
					)(data)
						.then(async function (result) {
							let selectedDate = new Date(route.params.date);
							const dayOfTheWeek =
								daysOfTheWeek[selectedDate.getUTCDay()];
							const subscription = result.data.subscription;
							const db = firebase.firestore();
							let emailTemp = "";
							if (auth.currentUser.email == null && auth.currentUser.isAnonymous == false) {
								UserAPI.fetchUserByID(auth.currentUser.uid).then(
									(userDoc) => {
										emailTemp = userDoc.email;
										sendEmailSubscriptionOrderConfirmation(
											confirmationNumber,
											cartList,
											emailTemp
										);
									}
								);
							} else {
								sendEmailSubscriptionOrderConfirmation(
									confirmationNumber,
									cartList,
									email
								);
							}
							await db.collection("Orders").add({
								companyName: route.params.companyName,
								confirmationNumber: confirmationNumber,
								locationForOrder: route.params.locationForOrder,
								orderMadeAt: Date.now(),
								providerID: route.params.providerInfo.userID,
								selectedDay: route.params.date,
								selectedTime: selectedTime,
								serviceName: route.params.cartItems,
								stripeConnectAccount:
									route.params.stripeConnectAccount,
								subscriptionID: subscription.id,
								subTotal: route.params.cartItems.current.price,
								user: auth.currentUser.uid,
								weekOfTheMonth: weekOfTheMonth,
								weekOfTheMonth2: "",
								dayOfTheWeek: dayOfTheWeek,
								interval: route.params.providerServiceInterval,
								intervalTimeUnit: route.params.providerIntervalUnit,
								providerFirstName:
									route.params.providerInfo.firstName,
								providerLastName:
									route.params.providerInfo.lastName,
								customerFirstName:
									firstName,
								customerLastName:
									lastName,
								emailReminder: false,
								customerEmail: emailTemp,
								lastDayOfSubscription: bookingQueue[bookingQueue.length - 1].selectedDay,
							});

							return {
								subscription: subscription,
							};
						})
						.catch(console.log);
					navigation.navigate("ServicePage", { appointmentBooked: true });
				} else if (result == false) {
					setLoading(false);
					setHelperMessageVisible(true);
				} else if (result == "concurrency issue") {
					setLoading(false);
					setHelperMessageVisible1(true);
				}
			})
		} else {
			const cartList = [
				{
					cartOptions: route.params.cartOptions,
					companyName: route.params.companyName,
					customerID: customerID,
					interval: route.params.providerServiceInterval,
					intervalTimeUnit: route.params.providerIntervalUnit,
					locationForOrder: route.params.locationForOrder,
					selectedDay: route.params.date,
					selectedTime: selectedTime,
					serviceName: route.params.cartItems,
					stripeConnectAccount: route.params.stripeConnectAccount,
					subTotal: priceSum,
					user: auth.currentUser.uid,
				},
			];
			const weekOfTheMonth = nthOccurrenceOfDay;
			let selectedDate = new Date(route.params.date);
			const dayOfTheWeek = daysOfTheWeek[selectedDate.getUTCDay()];
			const db = firebase.firestore();
			let emailTemp = "";
			if (auth.currentUser.email == null && auth.currentUser.isAnonymous == false) {
				UserAPI.fetchUserByID(auth.currentUser.uid).then(
					(userDoc) => {
						emailTemp = userDoc.email;
						sendEmailSubscriptionOrderConfirmation(
							confirmationNumber,
							cartList,
							emailTemp
						);
					}
				);
			} else {
				sendEmailSubscriptionOrderConfirmation(
					confirmationNumber,
					cartList,
					email
				);
			}

			updateAvailabilitySubscription(route.params.date, selectedTime).then(async (result) => {
				if (result == true) {
					await db.collection("Orders").add({
						companyName: route.params.companyName,
						locationForOrder: route.params.locationForOrder,
						confirmationNumber: confirmationNumber,
						orderMadeAt: Date.now(),
						providerID: route.params.providerInfo.userID,
						selectedDay: route.params.date,
						selectedTime: selectedTime,
						serviceName: route.params.cartItems,
						subTotal: route.params.cartItems.current.price,
						user: auth.currentUser.uid,
						weekOfTheMonth: weekOfTheMonth,
						weekOfTheMonth2: "",
						dayOfTheWeek: dayOfTheWeek,
						interval: route.params.providerServiceInterval,
						intervalTimeUnit: route.params.providerIntervalUnit,
						providerFirstName: route.params.providerInfo.firstName,
						providerLastName: route.params.providerInfo.lastName,
						customerFirstName: firstName,
						customerLastName: lastName,
						emailReminder: false,
						customerEmail: emailTemp,
						lastDayOfSubscription: bookingQueue[bookingQueue.length - 1].selectedDay,
					});
					if (auth.currentUser.email == null && auth.currentUser.isAnonymous == false) {
						let emailTemp = "";
						UserAPI.fetchUserByID(auth.currentUser.uid).then(
							(userDoc) => {
								emailTemp = userDoc.email;
								sendEmailSubscriptionOrderConfirmation(
									confirmationNumber,
									cartList,
									emailTemp
								);
							}
						);
					} else {
						sendEmailSubscriptionOrderConfirmation(
							confirmationNumber,
							cartList,
							email
						);
					}
					navigation.navigate("ServicePage", { appointmentBooked: true });
				} else if (result == false) {
					setLoading(false);
					setHelperMessageVisible(true);
				} else if (result == "concurrency issue") {
					setLoading(false);
					setHelperMessageVisible1(true);
				}
			})
		}
		setModalVisible(false);
	};

	const sendEmailOrderConfirmation = async (
		confirmationNumber,
		cartList,
		userEmail
	) => {
		let tax = "";
		let total = "";
		if (route.params.locationForOrder.includes("Québec") || route.params.locationForOrder.includes("QC")) {
			tax =
				Math.round(
					(cartList[0].subTotal * 0.14975 + Number.EPSILON) * 100
				) / 100;
			total = cartList[0].subTotal + tax;
		} else if (route.params.locationForOrder.includes("Ontario") || route.params.locationForOrder.includes("ON")) {
			tax =
				Math.round(
					(cartList[0].subTotal * 0.13 + Number.EPSILON) * 100
				) / 100;
			total = cartList[0].subTotal + tax;
		}

		let data = {
			dest: userEmail,
			locationForOrder: route.params.locationForOrder,
			purchasedItems: cartList,
			purchasedItemSubTotal: cartList[0].subTotal,
			tax: tax,
			total: total * 100,
			confirmationNumber: confirmationNumber,
		};
		const functions = getFunctions();
		const response = await httpsCallable(
			functions,
			"sendConfirmationEmail"
		)(data)
			.then(function (result) {
				return {
					result: null,
				};
			})
			.catch(console.log);
		return response;
	};

	const sendEmailSubscriptionOrderConfirmation = async (
		confirmationNumber,
		cartList,
		userEmail
	) => {
		let tax = "";
		let total = "";
		if (route.params.locationForOrder.includes("Québec") || route.params.locationForOrder.includes("QC")) {
			tax =
				Math.round(
					(cartList[0].subTotal * 0.14975 + Number.EPSILON) * 100
				) / 100;
			total = cartList[0].subTotal + tax;
		} else if (route.params.locationForOrder.includes("Ontario") || route.params.locationForOrder.includes("ON")) {
			tax =
				Math.round(
					(cartList[0].subTotal * 0.13 + Number.EPSILON) * 100
				) / 100;
			total = cartList[0].subTotal + tax;
		}

		let data = {
			dest: userEmail,
			locationForOrder: route.params.locationForOrder,
			purchasedItems: cartList,
			purchasedItemSubTotal: cartList[0].subTotal,
			tax: tax,
			total: total * 100,
			confirmationNumber: confirmationNumber,
		};
		const functions = getFunctions();
		const response = await httpsCallable(
			functions,
			"sendSubscriptionConfirmationEmail"
		)(data)
			.then(function (result) {
				return {
					result: null,
				};
			})
			.catch(console.log);
		return response;
	};

	const sendPushNotification = async (userReceivingPushNotification) => {
		const data = {
			userReceivingPushNotification: userReceivingPushNotification,
			notificationTitle: "New Order",
			notificationMessage: "You have a new order waiting for you!",
			notificationType: "orders"
		};
		const functions = getFunctions();
		const response = await httpsCallable(
			functions,
			"sendPushNotification"
		)(data)
			.then(function (result) {
				return {
					result: null,
				};
			})
			.catch(console.log);
		return response;
	};

	const updateBookingQueueMonthlyBiWeekly = async () => {
		try {
			// Create an array of promises for each async operation
			const promises = bookingQueue.map(async (booking) => {
				await UserAPI.updateUserFieldPromise(providerDoc.docID, {
					bookingQueue: arrayUnion(booking)
				});
			});

			// Wait for all promises to complete before resolving
			await Promise.all(promises);
			return true;
		} catch (error) {
			console.error(error);
			throw error; // Rethrow the error to be handled in the caller
		}
	}

	const generateBookingQueueBiWeeklyMonthly = async (selectedTime) => {
		let bookingQueueTemp = [];
		let userDocID = "";
		let nthOccurrenceOfDay = "";
		let selectedDate = new Date(route.params.date);
		let selectedDay = selectedDate.getUTCDate();
		let found = false;
		let countDayOfTheWeekOccurrence = 0;
		let timeStamp = moment(Date.now()).unix();
		const daysOfTheWeek = [
			"sunday",
			"monday",
			"tuesday",
			"wednesday",
			"thursday",
			"friday",
			"saturday",
		];

		while (selectedDay > 0) {
			selectedDay = selectedDay - 7;
			if (selectedDay == 0 && countDayOfTheWeekOccurrence == 0) {
				found = true;
			} else {
				countDayOfTheWeekOccurrence += 1;
			}
		}
		if (found == true) {
			countDayOfTheWeekOccurrence = 1;
		} else if (countDayOfTheWeekOccurrence == 5) {
			countDayOfTheWeekOccurrence -= 1;
		}
		nthOccurrenceOfDay = countDayOfTheWeekOccurrence;

		if (route.params.cartItems.current.priceUnit == "month") {
			const dayOfTheMonth = selectedDate.getDate();
			const monthOfTheYear = selectedDate.getMonth();
			const weekOfTheMonth = nthOccurrenceOfDay;
			const dayOfTheWeek = daysOfTheWeek[selectedDate.getUTCDay()];
			let countOccurrence = 0;
			let countMonth = monthOfTheYear;
			let countDay = dayOfTheMonth;
			let tempDate = moment(route.params.date);

			while (countOccurrence <= 12) {
				const weekOfTheMonthTemp = getWeekOfTheMonth(tempDate);
				const dayOfTheWeekTemp = daysOfTheWeek[tempDate.weekday()];

				if (
					weekOfTheMonthTemp + 1 == weekOfTheMonth &&
					dayOfTheWeek == dayOfTheWeekTemp
				) {
					let adjustedDate =
						moment(tempDate).format("YYYY-MM-DD");

					bookingQueueTemp.push({
						selectedDay: adjustedDate,
						selectedTime: selectedTime,
						timeStamp: timeStamp,
						receiverID: auth.currentUser.uid,
						providerServiceInterval: route.params.providerServiceInterval,
						providerIntervalUnit: route.params.providerIntervalUnit
					})
					tempDate = tempDate.add(1, "month");
					tempDate = tempDate.startOf("month");
					countOccurrence += 1;
				} else {
					tempDate = tempDate.add(1, "day");
				}
			}
			setBookingQueue(bookingQueueTemp);
		}
		else if (
			route.params.cartItems.current.priceUnit == "bi-weekly"
		) {
			let countOccurrence = 0;
			let selectedDate = moment(route.params.date).format(
				"YYYY-MM-DD"
			);
			let selectedDateTemp = moment(route.params.date);

			while (countOccurrence <= 26) {
				if (countOccurrence == 0) {
					bookingQueueTemp.push({
						selectedDay: selectedDate,
						selectedTime: selectedTime,
						timeStamp: timeStamp,
						receiverID: auth.currentUser.uid,
						providerServiceInterval: route.params.providerServiceInterval,
						providerIntervalUnit: route.params.providerIntervalUnit
					})
				} else {
					selectedDateTemp = moment(selectedDateTemp).add(2, "weeks");
					let adjustedDate = selectedDateTemp.format("YYYY-MM-DD");
					bookingQueueTemp.push({
						selectedDay: adjustedDate,
						selectedTime: selectedTime,
						timeStamp: timeStamp,
						receiverID: auth.currentUser.uid,
						providerServiceInterval: route.params.providerServiceInterval,
						providerIntervalUnit: route.params.providerIntervalUnit
					})
				}
				countOccurrence += 1;
			}
			setBookingQueue(bookingQueueTemp);
		}
	}

	const updateAvailability = async (selectedDay, selectedTime) => {
		return new Promise(async (resolve, reject) => {
			let resultTemp = "";
			try {
				await UserAPI.updateUserFieldPromise(providerDoc.docID, {
					bookingQueue: firebase.firestore.FieldValue.arrayUnion({
						selectedDay: selectedDay,
						selectedTime: selectedTime,
						timeStamp: moment(Date.now()).unix(),
						receiverID: auth.currentUser.uid,
						providerServiceInterval: route.params.providerServiceInterval,
						providerIntervalUnit: route.params.providerIntervalUnit
					})
				})
			} catch (error) {
				reject(error);
			}

			const data = {
				route: route,
				selectedDay: selectedDay,
				selectedTime: selectedTime,
				receiverID: auth.currentUser.uid,
				providerServiceInterval: route.params.providerServiceInterval,
				providerIntervalUnit: route.params.providerIntervalUnit
			};

			const db = firebase.firestore();
			await db.collection('Users').doc(providerDoc.docID).firestore.waitForPendingWrites().then(async () => {
				const functions = getFunctions();
				const response = await httpsCallable(functions, "updateAvailability")(data);

				resultTemp = response.data;
				resolve(resultTemp);
				sendPushNotification(route.params.providerInfo.userID);
			});

		});
	};

	const updateAvailabilitySubscription = (selectedDay, selectedTime) => {
		return new Promise(async (resolve, reject) => {
			let resultTemp = "";
			try {
				await updateBookingQueueMonthlyBiWeekly();
				const data = {
					route: route,
					selectedDay: selectedDay,
					selectedTime: selectedTime,
					receiverID: auth.currentUser.uid,
					providerServiceInterval: route.params.providerServiceInterval,
					providerIntervalUnit: route.params.providerIntervalUnit
				};

				const db = firebase.firestore();
				await db.collection('Users').doc(providerDoc.docID).firestore.waitForPendingWrites();

				const functions = getFunctions();
				const response = await httpsCallable(functions, "handleSubscriptionAvailability")(data);

				resultTemp = response.data;
				resolve(resultTemp);
				sendPushNotification(route.params.providerInfo.userID);

			} catch (error) {
				reject(error);
			}

		});
	};

	const updateAvailabilityCart = (selectedDay, selectedTime) => {
		return new Promise(async (resolve, reject) => {
			let resultTemp = "";
			try {
				await UserAPI.updateUserFieldPromise(providerDoc.docID, {
					bookingQueue: arrayUnion({
						selectedDay: selectedDay,
						selectedTime: selectedTime,
						timeStamp: moment(Date.now()).unix(),
						receiverID: auth.currentUser.uid,
						providerServiceInterval: route.params.providerServiceInterval,
						providerIntervalUnit: route.params.providerIntervalUnit
					})
				});
				const data = {
					route: route,
					selectedDay: selectedDay,
					selectedTime: selectedTime,
					receiverID: auth.currentUser.uid,
					providerServiceInterval: route.params.providerServiceInterval,
					providerIntervalUnit: route.params.providerIntervalUnit
				};

				const db = firebase.firestore();
				await db.collection('Users').doc(providerDoc.docID).firestore.waitForPendingWrites();

				const functions = getFunctions();
				const response = await httpsCallable(functions, "checkCartAvailability")(data);

				resultTemp = response.data;
				resolve(resultTemp);

			} catch (error) {
				reject(error);
			}

		});
	};


	const handleTime = (item) => {
		if (
			Math.abs(Math.floor(item.time) - item.time) * 60 != 0 &&
			Math.round(Math.abs(Math.floor(item.time) - item.time) * 60) !=
			60 &&
			Math.round(Math.abs(Math.floor(item.time) - item.time) * 60) >= 10
		) {
			return (
				<TextButton
					onPress={() => {
						setSelectedTime(item.time);
						generateBookingQueueBiWeeklyMonthly(item.time);
						setModalVisible(true);
					}}
					text={
						Math.floor(item.time) +
						":" +
						Math.round(
							Math.abs(Math.floor(item.time) - item.time) * 60
						)
					}
					customStyle={styles.modalButtons}
					customButtonColor={true}
				/>
			);
		} else if (Math.abs(Math.floor(item.time) - item.time) * 60 == 0) {
			return (
				<TextButton
					onPress={() => {
						generateBookingQueueBiWeeklyMonthly(item.time)
						setModalVisible(true)
						setSelectedTime(item.time);
					}}
					text={Math.floor(item.time) + ":" + "00"}
					customStyle={styles.modalButtons}
					customButtonColor={true}
				/>
			);
		} else if (
			Math.round(Math.abs(Math.floor(item.time) - item.time) * 60) == 60
		) {
			return (
				<TextButton
					onPress={() => {
						generateBookingQueueBiWeeklyMonthly(item.time)
						setModalVisible(true)
						setSelectedTime(item.time);
					}}
					text={Math.floor(item.time) + 1 + ":" + "00"}
					customStyle={styles.modalButtons}
					customButtonColor={true}
				/>
			);
		} else if (
			Math.round(Math.abs(Math.floor(item.time) - item.time) * 60) < 10
		) {
			return (
				<TextButton
					onPress={() => {
						generateBookingQueueBiWeeklyMonthly(item.time)
						setModalVisible(true)
						setSelectedTime(item.time);
					}}
					text={
						Math.floor(item.time) +
						":" +
						"0" +
						Math.round(
							Math.abs(Math.floor(item.time) - item.time) * 60
						)
					}
					customStyle={styles.modalButtons}
					customButtonColor={true}
				/>
			);
		}
	};

	function validateEmailAddress() {
		// Check if the email contains the "@" symbol
		if (email.indexOf('@') === -1) {
			setEmailValid(false);
			return false;
		}

		// Split the email address into two parts: local and domain
		const [localPart, domainPart] = email.split('@');

		// Check if both parts have content
		if (!localPart || !domainPart) {
			setEmailValid(false);
			return false;
		}

		// Check if the domain part contains a "." symbol
		if (domainPart.indexOf('.') === -1) {
			setEmailValid(false);
			return false;
		}

		// Check if the local part does not contain illegal characters
		const localPartPattern = /^[a-zA-Z0-9._%+-]+$/;
		if (!localPartPattern.test(localPart)) {
			setEmailValid(false);
			return false;
		}
		setEmailValid(true);
		return true;
	}

	function validateName(name, firstOrLastName) {
		if (firstOrLastName == 0 && name == "") {
			setFirstNameValid(false);
			return false;
		} else if (firstOrLastName == 1 && name == "") {
			setLastNameValid(false);
			return false;
		} else if (firstOrLastName == 0 && name != "") {
			setFirstNameValid(true);
			return true;
		} else if (firstOrLastName == 1 && name != "") {
			setLastNameValid(true);
			return true;
		}
		return true;
	}

	const handleTax = () => {
		const cartList = [
			{
				cartOptions: route.params.cartOptions,
				companyName: route.params.companyName,
				customerID: customerID,
				interval: route.params.providerServiceInterval,
				intervalTimeUnit: route.params.providerIntervalUnit,
				locationForOrder: route.params.locationForOrder,
				selectedDay: route.params.date,
				selectedTime: selectedTime,
				serviceName: route.params.cartItems,
				stripeConnectAccount: route.params.stripeConnectAccount,
				subTotal: priceSum,
				user: auth.currentUser.uid,
			},
		];
		let taxTemp = "";
		let total = 0;
		if (
			route.params.locationForOrder.includes("Québec") ||
			route.params.locationForOrder.includes("QC")
		) {
			taxTemp =
				Math.round(
					(cartList[0].subTotal * 0.14975 + Number.EPSILON) * 100
				) / 100;
			total = Math.round((cartList[0].subTotal + taxTemp) * 100) / 100;
		} else if (
			route.params.locationForOrder.includes("Ontario") ||
			route.params.locationForOrder.includes("ON")
		) {
			taxTemp =
				Math.round(
					(cartList[0].subTotal * 0.13 + Number.EPSILON) * 100
				) / 100;
			total = Math.round((cartList[0].subTotal + taxTemp) * 100) / 100;
		}
		return <Text>{total.toFixed(2)}</Text>;
	};

	return (
		<SafeAreaView style={{ alignItems: "center", flexDirection: "column", overflow: enableScroll, height: SCREEN_HEIGHT }}>
			<View
				style={{
					backgroundColor: "white",
					width: SCREEN_WIDTH,
					alignItems: "center"
				}}
			>
				<IconButton
					onPress={() => {
						navigation.navigate("ServicePage")
					}}
					iconType="arrow-back-circle"
					customStyle={{
						width: 50,
						height: 40,
						borderRadius: 100,
						backgroundColor: "transparent",
						position: "absolute",
						top: 0,
						left: 10,
					}}
					customTextStyle={{
						fontSize: 40,
						color: "#36454f",
						fontWeight: "bold",
					}}
				/>
				<Text
					style={{
						color: "#36454f",
						fontSize: 25,
						marginTop: SCREEN_HEIGHT * 0.07,
						fontWeight: "bold"
					}}
				>
					Availabilities
				</Text>
				<Text style={{ fontSize: 15 }}>{route.params.date}</Text>
			</View>

			<View
				style={{
					position: "absolute",
					top: SCREEN_HEIGHT * 0.86,
					zIndex: 21,
					height: SCREEN_HEIGHT * 0.1,
					width: SCREEN_WIDTH
				}}
			>
				<Snackbar
					style={{ backgroundColor: "#232b2b" }}
					visible={helperMessageVisible}
					onDismiss={() => setHelperMessageVisible(false)}
					action={{
						color: "#5252ff",
						label: "Got it",
					}}
				>
					<Text style={{ color: "white" }}>This availability has just been booked, sorry!</Text>
				</Snackbar>
			</View>
			<View
				style={{
					position: "absolute",
					top: SCREEN_HEIGHT * 0.86,
					zIndex: 50,
					height: SCREEN_HEIGHT * 0.1,
					width: SCREEN_WIDTH,
				}}
			>
				<Snackbar
					style={{ backgroundColor: "#232b2b" }}
					visible={helperMessageVisible1}
					onDismiss={() => setHelperMessageVisible1(false)}
					action={{
						color: "#5252ff",
						label: "Got it",
					}}
				>
					<Text style={{ color: "white" }}>Something went wrong, please try again.</Text>
				</Snackbar>
			</View>

			<Modal animationType="fade" transparent visible={modalVisible}>
				<View
					style={{
						backgroundColor: "white",
						width: SCREEN_WIDTH,
						height: SCREEN_HEIGHT,
						alignItems: "center"
					}}
				>
					<IconButton
						onPress={() => {
							setModalVisible(false);
							setEnableScroll("scroll");
						}}
						iconType="close-circle"
						customStyle={{
							width: 50,
							height: 40,
							borderRadius: 100,
							backgroundColor: "transparent",
							position: "absolute",
							top: SCREEN_HEIGHT * 0,
							right: "5%",
						}}
						customTextStyle={{
							fontSize: 40,
							color: "#36454f",
							fontWeight: "bold",
						}}
					/>



					<View style={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.8 : SCREEN_WIDTH * 0.6, height: SCREEN_HEIGHT * 0.6, alignSelf: "center", justifyContent: "center", alignItems: "center", borderLeftColor: 'gray', borderRadius: 10, boxShadow: '2px 0 4px rgba(0, 0, 0, 0.1), -2px 0 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05), 0 -2px 4px rgba(0, 0, 0, 0.05)', paddingTop: 50, paddingBottom: 50, paddingLeft: 20, paddingRight: 20, position: "absolute", top: SCREEN_HEIGHT * 0.1 }}>
						<Text style={{ fontSize: 20, fontWeight: "400", position: "absolute", top: 10, color: "#36454f" }}>Information</Text>
						<View style={{ height: SCREEN_HEIGHT * 0.08, backgroundColor: "rgba(82, 82, 255, 0.1)", borderRadius: 8, padding: 10 }}>
							<Text
								style={{
									fontSize: 14,
									fontWeight: "500",
									color: "#36454f"
								}}
							>
								{route.params.cartItems?.current.name} •
								${route.params.cartItems?.current.price}
							</Text>
							{route.params.cartOptions.length == 1 ? (
								<></>
							) : (
								route.params.cartOptions?.map((e) => {
									return (
										<Text
											style={{
												fontSize: 14,
												color: "#36454f",
												fontWeight: "500",
												color: "#36454f"
											}}
										>
											{e.name} • ${e.price}
										</Text>
									);
								})
							)}

							{route.params.cancellationFee !=
								undefined ? (
								<Text
									style={{
										fontSize: 14,
										fontWeight: "500",
										color: "#36454f"
									}}
								>
									*Cancellation Fee • $
									{route.params.cancellationFee}
								</Text>
							) : (
								<></>
							)}
							<Text
								style={{
									fontSize: 13,
									fontWeight: "500",
									color: "#36454f"
								}}
							>
								with {route.params.providerInfo.firstName}
							</Text>
						</View>
						<CustomInput
							placeholder="First Name"
							value={firstName}
							setValue={(e) => {
								setFirstName(e)
								validateName(e, 0)
							}}
							inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
							validateFunction={() => validateName(firstName, 0)}
							maxLength={15}
							errorMessage={"Please provide your first name"}
						/>
						<CustomInput
							placeholder="Last Name"
							value={lastName}
							setValue={(e) => {
								setLastName(e)
								validateName(e, 1)
							}}
							inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
							validateFunction={() => validateName(lastName, 1)}
							maxLength={15}
							errorMessage={"Please provide your last name"}
						/>
						<CustomInput
							placeholder="Email"
							value={email}
							setValue={(e) => {
								setEmail(e)
								validateEmailAddress()
							}}
							inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
							validateFunction={validateEmailAddress}
							errorMessage={"Please enter a valid email"}
						/>
						<CustomInput
							placeholder="Phone Number"
							value={phoneNumber}
							setValue={(e) => {
								setPhoneNumber(e)
							}}
							inputStyles={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH * 0.3, height: SCREEN_HEIGHT * 0.05 }}
							// validateFunction={isNumberValidationFunction(3)}
							maxLength={15}
						/>
					</View>

					<View
						style={{
							position: "absolute", bottom: 30
						}}
					>
						{route.params.quotable != "quotable" &&
							route.params.quotable != "In-Store" &&
							route.params.cartItems?.current.priceUnit !=
							"month" &&
							route.params.cartItems?.current.priceUnit !=
							"bi-weekly" ? (
							<TextButton
								onPress={() => {
									addToCart(),
										setModalVisible(false)
								}}
								text="Add to Cart"
								customStyle={styles.modalButtons}
								disableButton={firstNameValid && lastNameValid && emailValid ? false : true}
							/>
						) : route.params.quotable != "quotable" &&
							route.params.quotable == "In-Store" &&
							route.params.cartItems?.current.priceUnit !=
							"month" &&
							route.params.cartItems?.current.priceUnit !=
							"bi-weekly" ? (
							<TextButton
								onPress={() => {
									addToOrders(),
										setModalVisible(false)
								}}
								text="Book"
								customStyle={styles.modalButtons}
								disableButton={firstNameValid && lastNameValid && emailValid ? false : true}
							/>
						) : route.params.quotable == "quotable" &&
							route.params.cartItems?.current.priceUnit !=
							"month" &&
							route.params.cartItems?.current.priceUnit !=
							"bi-weekly" ? (
							<TextButton
								onPress={() => {
									addToOrders(),
										setModalVisible(false)
								}}
								text="Book"
								customStyle={styles.modalButtons}
								disableButton={firstNameValid && lastNameValid && emailValid ? false : true}
							/>
						) : (
							<TextButton
								onPress={() => {
									handleSubscription(
										route.params.cartItems.current
											.priceID
									),
										setModalVisible(false)
								}}
								text={route.params.quotable === "subscription pay in-store" ? "Book Recurring" : "Subscribe"}
								customStyle={styles.modalButtons}
								disableButton={firstNameValid && lastNameValid && emailValid ? false : true}
							/>
						)}
					</View>
				</View>
			</Modal>

			{
				scheduleList.length != 0 &&
					scheduleList != undefined &&
					loading == false ? (
					<View style={{ overflow: "hidden" }}>
						<FlatList
							style={{ overflow: "hidden" }}
							data={scheduleList}
							showsVerticalScrollIndicator={false}
							keyExtractor={(_, index) => index.toString()}
							// scrollEnabled={enableScroll}
							renderItem={({ item, separators }) => (
								<TextButton
									onPress={() => {
										setModalVisible(true),
											setSelectedTime(item.time),
											setEnableScroll("hidden");
									}}
									text={handleTime(item)}
									customStyle={{ width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.8 : SCREEN_WIDTH * 0.3 }}
									customButtonColor={true}
									customButtonTextColor={false}
								/>
							)}
						/>
					</View>
				) : scheduleList.length == 0 &&
					scheduleList == undefined &&
					loading == false ? (
					<Text style={{ fontSize: 20, fontWeight: "500", marginTop: SCREEN_HEIGHT * 0.1 }}>
						No availabilities for this date
					</Text>
				) : (
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							// marginTop: SCREEN_HEIGHT * 0.15,
							zIndex: 4,
							position: "absolute",
							top: SCREEN_HEIGHT * 0.4
						}}
					>
						<LottieView
							source={require("../../../../assets/Loading Spinner.json")}
							autoPlay
							loop
							style={{ height: SCREEN_HEIGHT * 0.05 }}
						/>
					</View>
				)
			}
		</SafeAreaView>
	);
};
const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "column",
		resizeMode: "contain"
	},
	containerHeader: {
		flex: 1,
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
		height: 5,
		width: 90,
		backgroundColor: "gray",
		marginTop: Dimensions.get("window").height * 0.3,
		borderRadius: 50,
	},
	headerContent: {
		marginTop: SCREEN_HEIGHT * 0.07,
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
	},
	modalStyles: {
		backgroundColor: "white",
		marginTop: Dimensions.get("window").height * 0.1,
	},
	modalButtons: {
		// position: "absolute",
		// bottom: SCREEN_HEIGHT * 0.1
	},

	tinyLogo: {
		width: SCREEN_WIDTH * 0.1,
		height: SCREEN_WIDTH * 0.1,
		borderRadius: 10,
		marginLeft: SCREEN_WIDTH * 0.03
	},
});

export default Availabilities;
