import React from "react";
import { StatusBar } from 'expo-status-bar';
import { Dimensions, Linking, Platform, StyleSheet, Text, View } from 'react-native';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firebaseApp } from './index';
import { NavigationContainer, DefaultTheme, useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { ServicePage } from "./src/shared/HomeScreenTabs/ServicePage/ServicePage"
import { LandingPage } from "./src/shared/LandingPage/LandingPage"
import Availabilities from "./src/shared/HomeScreenTabs/ServicePage/Availabilities";
import LottieView from "lottie-react-native";

export default function App() {
	const [doc, setDoc] = useState("");
	const [SCREEN_HEIGHT, setSCREEN_HEIGHT] = useState(Dimensions.get('window').height);
	const [SCREEN_WIDTH, setSCREEN_WIDTH] = useState(Dimensions.get('window').width);
	const [showLandingPage, setShowLandingPage] = useState(true);
	const [loading, setLoading] = useState(true);
	const Stack = createStackNavigator();

	useEffect(() => {
		getProviderIDFromURL();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			if (Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone')) {
				setSCREEN_HEIGHT(Dimensions.get('screen').height);
				setSCREEN_WIDTH(Dimensions.get('screen').width);
			} else {
				setSCREEN_HEIGHT(Dimensions.get('window').height);
				setSCREEN_WIDTH(Dimensions.get('window').width);
			}
		};

		Dimensions.addEventListener('change', handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			Dimensions.removeEventListener('change', handleResize);
		};
	}, []);

	const getProviderIDFromURL = async () => {
		let providerIDTemp = "";
		let urlArrayWithID = "";

		await Linking.getInitialURL().then(async (url) => {
			let urlTemp = JSON.stringify(url);
			let urlArray = Array.from(urlTemp);
			const indexToSpliceFrom = urlArray.findIndex((char) =>
				char === "="
			);

			if (indexToSpliceFrom != -1) {
				setShowLandingPage(false);
				urlArrayWithID = urlArray.slice(indexToSpliceFrom + 1, urlArray.length - 1);
				providerIDTemp = urlArrayWithID.join("");
			} else {
				setShowLandingPage(true);
			}
			return;
		});

		setLoading(false);
	}

	return (
		<NavigationContainer>
			<StatusBar style="dark" />
			<Stack.Navigator>
				{showLandingPage ?
					<Stack.Screen
						// style={styles.container}
						options={{ headerShown: false }}
						name="Slyx"
						component={LandingPage}
					/>
					:
					<Stack.Screen
						// style={styles.container}
						options={{ headerShown: false }}
						name="Book"
						component={ServicePage}
					/>
				}
				<Stack.Screen
					style={styles.container}
					options={{ headerShown: false, title: "", headerStyle: { height: 30, borderBottomWidth: 0 } }}
					name="Availabilities"
					component={Availabilities}
				/>
			</Stack.Navigator>
		</NavigationContainer>
	);
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'red',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
