import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	Image,
	Platform,
	Dimensions,
	StyleSheet,
	KeyboardAvoidingView
} from "react-native";
import { TouchableOpacity } from "react-native-web";
// import { SliderBox } from "react-native-image-slider-box";
import { LinearGradient } from "expo-linear-gradient";
import { desktopLandingPage } from "./desktopLandingPage";
import { mobileLandingPage } from "./mobileLandingPage";
import { getFunctions, httpsCallable } from "firebase/functions";

export const LandingPage = () => {
	const [SCREEN_HEIGHT, setSCREEN_HEIGHT] = useState(Dimensions.get('window').height);
	const [SCREEN_WIDTH, setSCREEN_WIDTH] = useState(Dimensions.get('window').width);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [emailValid, setEmailValid] = useState(false);
	const [firstNameValid, setFirstNameValid] = useState(false);
	const [lastNameValid, setLastNameValid] = useState(false);
	const [enableScroll, setEnableScroll] = useState("hidden");
	const [showDemoForm, setShowDemoForm] = useState(false);
	const textColor = "white";
	const textColorContrast = "#11111a";
	const [mobileWebPage, setMobileWebPage] = useState(Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone'));

	const styles = StyleSheet.create({
		container: {
			width: SCREEN_WIDTH,
			height: SCREEN_HEIGHT,
			flex: 1,
			alignItems: "center",
			zIndex: 0,
			overflow: enableScroll
		},
		containerGradient: {
			width: SCREEN_WIDTH,
			height: SCREEN_HEIGHT,
			alignItems: "center",
			flex: 1,
			overflow: enableScroll
		},
		header: {
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			backgroundColor: "rgba(10, 10, 71,0.6)",
			padding: 20,
			width: SCREEN_WIDTH,
		},
		logo: {
			color: textColor,
			fontSize: 24,
			fontWeight: "bold"
		},
		buttonContainer: {
			flexDirection: "row",
			alignItems: "center",
		},
		menuButton: {
			marginHorizontal: 10,
			color: textColor,
		},
		button: {
			backgroundColor: "#5252ff",
			paddingVertical: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
		},
		heroButton: {
			marginTop: SCREEN_HEIGHT * 0.05,
			backgroundColor: "#5252ff",
			paddingVertical: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: SCREEN_WIDTH * 0.2,
			height: SCREEN_HEIGHT * 0.05,
			alignItems: "center",
			justifyContent: "center"
		},
		buttonText: {
			color: "#fff",
			fontSize: SCREEN_WIDTH * 0.01,
			fontWeight: "bold",
		},
		hero: {
			width: SCREEN_WIDTH * 0.35,
			marginRight: SCREEN_WIDTH * 0.05,
		},
		heroText: {
			color: textColor,
			fontSize: SCREEN_WIDTH * 0.027,
			fontWeight: "bold",
			marginBottom: 10,
		},
		heroSubText: {
			color: textColor,
			fontSize: SCREEN_WIDTH * 0.013,
		},
		heroMobile: {
			width: SCREEN_WIDTH * 0.35,
			marginRight: 40
		},
		heroTextMobile: {
			color: textColor,
			fontSize: 17,
			fontWeight: "bold",
			marginBottom: 10,
		},
		heroSubTextMobile: {
			color: textColor,
			fontSize: 15,
		},
		heroButtonMobile: {
			marginTop: SCREEN_HEIGHT * 0.02,
			backgroundColor: "#5252ff",
			paddingVertical: 10,
			paddingHorizontal: 20,
			borderRadius: 10,
			width: SCREEN_WIDTH * 0.35,
			height: 50,
			alignItems: "center",
			justifyContent: "center"
		},
		buttonTextMobile: {
			color: "#fff",
			fontSize: 15,
			fontWeight: "bold",
		},
		footer: {

		},
		footerText: {
			color: textColorContrast,
			fontSize: 14,
		},
		demoFormContainer: { backgroundColor: "rgba(10, 10, 71,0.6)", width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.8 : SCREEN_WIDTH * 0.6, alignSelf: "center", justifyContent: "center", alignItems: "center", borderLeftColor: 'gray', borderRadius: 10, boxShadow: '2px 0 4px rgba(0, 0, 0, 0.1), -2px 0 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05), 0 -2px 4px rgba(0, 0, 0, 0.05)', paddingTop: 70, paddingBottom: 70, paddingLeft: 20, paddingRight: 20, position: "absolute", top: SCREEN_HEIGHT * 0.1 }
	});

	useEffect(() => {
		const handleResize = () => {
			if (Platform.OS == "web" && navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone')) {
				if (Dimensions.get('window').height > Dimensions.get('window').width) {
					setEnableScroll("hidden")
				} else {
					setEnableScroll("scroll")
				}
				setSCREEN_HEIGHT(Dimensions.get('window').height);
				setSCREEN_WIDTH(Dimensions.get('window').width);
			} else {
				setSCREEN_HEIGHT(Dimensions.get('window').height);
				setSCREEN_WIDTH(Dimensions.get('window').width);
			}
		};

		Dimensions.addEventListener('change', handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			Dimensions.removeEventListener('change', handleResize);
		};
	}, []);

	function validateEmailAddress() {
		// Check if the email contains the "@" symbol
		if (email.indexOf('@') === -1) {
			setEmailValid(false);
			return false;
		}

		// Split the email address into two parts: local and domain
		const [localPart, domainPart] = email.split('@');

		// Check if both parts have content
		if (!localPart || !domainPart) {
			setEmailValid(false);
			return false;
		}

		// Check if the domain part contains a "." symbol
		if (domainPart.indexOf('.') === -1) {
			setEmailValid(false);
			return false;
		}

		// Check if the local part does not contain illegal characters
		const localPartPattern = /^[a-zA-Z0-9._%+-]+$/;
		if (!localPartPattern.test(localPart)) {
			setEmailValid(false);
			return false;
		}
		setEmailValid(true);
		return true;
	}

	function validateName(name, firstOrLastName) {
		if (firstOrLastName == 0 && name == "") {
			setFirstNameValid(false);
			return false;
		} else if (firstOrLastName == 1 && name == "") {
			setLastNameValid(false);
			return false;
		} else if (firstOrLastName == 0 && name != "") {
			setFirstNameValid(true);
			return true;
		} else if (firstOrLastName == 1 && name != "") {
			setLastNameValid(true);
			return true;
		}
		return true;
	}

	async function sendDemoFormInformation() {
		let data = {
			dest: "SlyxApp@gmail.com",
			firstName: firstName,
			// businessName: businessName,
			phoneNumber: phoneNumber,
			email: email
		};
		const functions = getFunctions();
		const response = await httpsCallable(
			functions,
			"sendProviderContactInfoEmail"
		)(data)
			.then(function (result) {
				console.log(result);
				return {
					result: null,
				};
			})
			.catch(console.log);
		return response;
	}

	return (
		<KeyboardAvoidingView behavior="padding" style={styles.container}>
			<LinearGradient colors={["#5252ff", "#5252ff", "white"]} style={styles.containerGradient}>
				<View style={styles.header}>
					<View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						<Image resizeMode="contain" style={{ height: 45, width: 45, marginRight: 20, borderRadius: 10 }} source={require("../../../assets/SlyxLogo.png")} />
						<Text style={styles.logo}>Slyx</Text>
					</View>

					<View style={styles.buttonContainer}>
						<Text style={styles.menuButton}>Home</Text>
						<Text style={styles.menuButton}>Pricing</Text>
						{mobileWebPage ?
							<></>
							:
							<TouchableOpacity onPress={() => { setShowDemoForm(true) }} style={styles.button}>
								<Text style={styles.buttonText}>Get Started</Text>
							</TouchableOpacity>
						}
					</View>
				</View>

				{mobileWebPage ?
					mobileLandingPage(showDemoForm, styles, setShowDemoForm, SCREEN_HEIGHT, SCREEN_WIDTH, setEnableScroll, firstName, setFirstName, validateName, lastName, setLastName, email, setEmail, validateEmailAddress, phoneNumber, setPhoneNumber, sendDemoFormInformation, firstNameValid, lastNameValid, emailValid)
					:
					desktopLandingPage(showDemoForm, styles, setShowDemoForm, SCREEN_HEIGHT, SCREEN_WIDTH, setEnableScroll, firstName, setFirstName, validateName, lastName, setLastName, email, setEmail, validateEmailAddress, phoneNumber, setPhoneNumber, sendDemoFormInformation, firstNameValid, lastNameValid, emailValid)
				}
				<View style={styles.footer}>
					<Text style={styles.footerText}>© 2024 Slyx</Text>
				</View>
			</LinearGradient>
		</KeyboardAvoidingView>
	);
};


