import React from "react";
import { Text, TouchableOpacity, StyleSheet } from "react-native";
import { screenDimensions } from "../../../utils/fileWithConstants";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const CheckBox = ({ onPress, text, customStyle }) => {
	const buttonStyle = {
		...styles.buttonStyle,
		...customStyle,
	};
	return (
		<TouchableOpacity onPress={onPress} style={buttonStyle}>
			<Text style={[styles.text]}>{text}</Text>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	buttonStyle: {
		width: SCREEN_WIDTH * 0.15,
		borderRadius: 15,
		height: SCREEN_HEIGHT * 0.06,
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 10,
		marginTop: 5,
		shadowRadius: 1,
		borderWidth: 0.65,
		position: "absolute",
	},
});

export default CheckBox;
