// import 'react-native-gesture-handler';
import { registerRootComponent } from 'expo';
// import * as Notifications from "expo-notifications";
import firebaseConfig from "./FirebaseApp";
import firebase from 'firebase/compat/app';
import { getAuth } from 'firebase/auth';
import App from './App';

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately


// messaging().setBackgroundMessageHandler(async remoteMessage => {
// 	console.log('Message handled in the background!', remoteMessage);
//   });
registerRootComponent(App);

export { firebaseApp, auth }