import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { screenDimensions } from "../../../utils/fileWithConstants";
// import AnimatedLottieView from "lottie-react-native";
// import LottieView from "lottie-react-native";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const DynamicTextIconButton = ({
	onPress,
	text,
	containerStyleProp,
	textStyleProp,
	loading,
}) => {
	const ref = React.useRef();

	const containerStyle = {
		...styles.container,
		...containerStyleProp,
	};

	const textStyle = {
		...styles.text,
		...textStyleProp,
	};
	return (
		<TouchableOpacity onPress={onPress} style={containerStyle}>
			{loading == true ? (
				<View
					style={{
						height: SCREEN_HEIGHT * 0.2,
						width: SCREEN_WIDTH * 0.2,
						flexDirection: "row",
						marginLeft: SCREEN_WIDTH * 0.27,
					}}
				>
					{/* <LottieView
						style={{ position: "absolute", top: 0 }}
						source={require("../../../../assets/LoadingSpinner.json")}
						autoPlay
						loop
					/> */}
				</View>
			) : (
				<></>
			)}
			<Text style={textStyle}>{text}</Text>
		</TouchableOpacity>
	);
};
const styles = StyleSheet.create({
	container: {
		width: SCREEN_WIDTH * 0.5,
		borderRadius: 10,
		height: SCREEN_HEIGHT * 0.06,
		justifyContent: "center",
		alignSelf: "center",
		// alignItems: "center",
		// marginVertical: 10,
		// marginTop: SCREEN_HEIGHT * 0.002,
		// flexDirection: "row",
	},

	text: {
		fontWeight: "bold",
		color: "white",
		// position: "absolute",
		// right: SCREEN_WIDTH * 0.22,
	},
});
export default DynamicTextIconButton;
