import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { firebaseApp } from '../../index';


const db = getFirestore(firebaseApp);
;

export const addOptionPackage = async (addPackage) => {
	try {
		await db.collection("Options&Packages").add(addPackage);
		fetchServices();
		setLoading(false);
	} catch (e) {
		console.log(e);
	}
};

export const fetchOptionPackageDocs = async () => {
	try {
		return await db
			.collection("Options&Packages")
			.get()
			.then((optionPackages) => {
				return optionPackages;
			});
	} catch (e) {
		console.log(e);
	}
};

export const fetchOptionPackageDocsByUserID = async (userID) => {
	try {
		return await db
			.collection("Options&Packages")
			.where("user", "==", userID)
			.get()
			.then((optionPackages) => {
				return optionPackages;
			});
	} catch (e) {
		console.log(e);
	}
};

export const deleteOptionPackage = async (docID) => {
	db.collection("Options&Packages").doc(docID).delete();
	return null;
};

export const setOptionPackage = async (optionPackage, id) => {
	let query = "";
	try {
		query = await fetchOptionPackageDocs().then((optionPackagesQuery) => {
			optionPackagesQuery.forEach((optionPackageQuery) => {
				if (optionPackageQuery.id == id) {
					db.collection("Options&Packages")
						.doc(id)
						.set(optionPackage);
				}
			});
		});
	} catch (e) {
		console.log(e);
	}

	return query;
};

export const fetchOptionPackages = async (userID, routeServiceID) => {
	const checkMarkList = [];
	let optionPackagesList = [];

	await fetchOptionPackageDocsByUserID(userID).then((optionPackagesQuery) => {
		optionPackagesQuery.forEach((doc) => {
			const {
				name,
				description,
				price,
				priceID,
				image,
				created,
				category,
				serviceName,
				serviceID,
			} = doc.data();
			if (serviceID == routeServiceID) {
				optionPackagesList.push({
					title: category,
					data: [
						{
							id: doc.id,
							name: name,
							description: description,
							price: price,
							priceID: priceID,
							image: image,
							created: created,
						},
					],
				});

				checkMarkList.push({
					name: name,
					color: "transparent",
				});
			}
		});
	});

	var filter_data = {};
	optionPackagesList.forEach((e) => {
		if (filter_data[e.title] != undefined) {
			filter_data[e.title].data = [
				...filter_data[e.title].data,
				...e.data,
			];
		} else {
			filter_data[e.title] = e;
		}
	});
	var _data = Object.values(filter_data);
	return { data: _data, checkMarkList: checkMarkList };
};
