import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { screenDimensions } from "../../../utils/fileWithConstants";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const TextIconButtonCustom = ({
	onPress,
	text,
	containerStyleProp,
	textStyleProp,
	buttonType,
}) => {
	const containerStyle = {
		...styles.container,
		...containerStyleProp,
	};

	const textStyle = {
		...styles.text,
		...textStyleProp,
	};
	return (
		<TouchableOpacity onPress={onPress} style={containerStyle}>
			<View style={{ flexDirection: "row", alignItems: "center" }}>
				<Ionicons
					style={{ color: "#36454f", fontSize: 25 }}
					name={buttonType}
					type="ionicon"
				/>
				<Text style={textStyle}>{text}</Text>
			</View>
		</TouchableOpacity>
	);
};
const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "row",
		marginBottom: SCREEN_HEIGHT * 0.02
	},

	text: {
		fontWeight: "400",
		marginLeft: 10
	},
});
export default TextIconButtonCustom;
