import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";

const IconButton = ({
	onPress,
	iconType,
	customStyle,
	customTextStyle,
	disabled,
}) => {
	const containerStyle = {
		...styles.container,
		...customStyle,
	};
	const textStyle = {
		...styles.text,
		...customTextStyle,
	};
	return (
		<TouchableOpacity
			onPress={onPress}
			disabled={disabled}
			style={containerStyle}
		>
			<Ionicons style={textStyle} name={iconType} type="ionicon" />
		</TouchableOpacity>
	);
};
const styles = StyleSheet.create({
	container: {
		width: 30,
		height: 45,
		borderRadius: 25,
	},
	text: {
		color: "#36454f",
		fontSize: 25,
	},
});

export default IconButton;
