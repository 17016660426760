import React from "react";
import { View, Text, StyleSheet, TouchableOpacity, Image } from "react-native";
import { screenDimensions } from "../../../utils/fileWithConstants";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const TextButtonWithImage = ({
	onPress,
	text,
	customStyle,
	customTextStyle,
	customButtonColor,
	imageSource,
}) => {
	const containerStyle = {
		...styles.container,
		...customStyle,
	};
	const textStyle = {
		...styles.text,
		...customTextStyle,
	};
	if (!customButtonColor) {
		containerStyle.backgroundColor = "#5252ff";
	}
	return (
		<TouchableOpacity onPress={onPress} style={containerStyle}>
			<View
				style={{
					width: SCREEN_WIDTH * 0.07,
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Image
					resizeMode="stretch"
					style={{
						height: SCREEN_HEIGHT * 0.055,
						width: SCREEN_HEIGHT * 0.055,
						borderRadius: 50,
						borderColor: "#36454f",
						borderWidth: 0.65,
						marginBottom: 10
					}}
					source={{ uri: imageSource }}
				/>
				<Text
					style={{
						fontWeight: "500",
						fontSize: 20,
						color: "#36454f",
						marginLeft: SCREEN_WIDTH * 0.01
					}}
				>
					{text}
				</Text>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	container: {
		width: 340,
		height: SCREEN_HEIGHT * 0.06,
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 10,
	},

	text: {
		fontWeight: "600",
		fontSize: 20,
		color: "white",
	},
});

export default TextButtonWithImage;
