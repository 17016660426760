import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Dimensions, Platform } from "react-native";
import { HelperText, TextInput } from "react-native-paper";
import { screenDimensions } from "../../../utils/fileWithConstants";

const { SCREEN_WIDTH, SCREEN_HEIGHT } = screenDimensions;

const CustomInput = ({
	disabled,
	value,
	setValue,
	placeholder,
	secureTextEntry,
	autoCorrect = false,
	autoCapitalize = "none",
	keyboardType,
	inputStyles,
	maxLength,
	validateFunction = defaultValidationFunction,
	errorMessage,
	autocomplete
}) => {
	if (maxLength == undefined) {
		maxLength = 30;
	}

	const [showHelperText, setShowHelperText] = useState(false);
	const [borderColor, setBorderColor] = useState("#a8a7b8");
	const [SCREEN_HEIGHT, setSCREEN_HEIGHT] = useState(Dimensions.get('window').height);
	const [SCREEN_WIDTH, setSCREEN_WIDTH] = useState(Dimensions.get('window').width);

	const containerStyle = {
		...styles.container,
		...inputStyles,
		borderColor: borderColor,
	};

	const handleOnBlur = () => {
		setShowHelperText(true);
		if (validateFunction(value)) {
			setShowHelperText(false);
			setBorderColor("#a8a7b8");
		} else {
			setBorderColor("red");
			setShowHelperText(true);
		}
	};

	const handleOnFocus = () => {
		setShowHelperText(false);
		setBorderColor("#5252ff");
	};

	return (
		<>
			<TextInput
				outlineColor={borderColor}
				disabled={disabled}
				mode="outlined"
				placeholderTextColor="#36454f"
				maxLength={maxLength}
				value={value}
				onChangeText={setValue}
				placeholder={placeholder}
				style={containerStyle}
				theme={{
					roundness: 10,
					colors: {
						primary: borderColor,
						underlineColor: "transparent",
						text: "#36454f"
					},
				}}
				secureTextEntry={secureTextEntry}
				autoCorrect={autoCorrect}
				autoCapitalize={autoCapitalize}
				keyboardType={keyboardType}
				onBlur={() => handleOnBlur()}
				onFocus={() => handleOnFocus()}
				autoComplete={autocomplete}
			/>
			{/*
			 * ShowHelperText handles onFocus
			 * value ensures the text is not empty
			 * errorFunction is custom function for each input
			 */}
			{showHelperText ? (
				<HelperText type="error" visible={true}>
					{errorMessage}
				</HelperText>
			) : (
				<></>
			)}
		</>
	);
};

const defaultValidationFunction = (value) => {
	return true;
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: "white",
		width: SCREEN_WIDTH < 1000 ? SCREEN_WIDTH * 0.9 : SCREEN_WIDTH * 0.87,
		// height: SCREEN_HEIGHT * 0.06,
		marginVertical: 10
	},
});

export default CustomInput;
