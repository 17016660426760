import { getFirestore, collection, getDocs, query, where, doc, runTransaction } from 'firebase/firestore';
import { firebaseApp } from '../../index';

import { auth } from "../../index";
// import { calculateGeoDistance, isInRange } from "../utils/geoFunction";

const db = getFirestore(firebaseApp);
;

export const fetchUserByID = async (userID) => {
	let user = "";
	try {
		const q = query(collection(db, "Users"), where("userID", "==", userID));

		await getDocs(q)
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					user = doc.data();
				});
			});
		return user;
	} catch (e) {
		console.log(e);
	}
};

export const fetchUserByIDWithDocID = async (userID) => {
	let user = "";
	let docID = "";
	try {
		const q = query(collection(db, "Users"), where("userID", "==", userID));

		await getDocs(q)
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					user = doc.data();
					docID = doc.id;
				});
			});
	} catch (e) {
		console.log(e);
	}
	return { user: user, docID: docID };
};

export const fetchUserDocTransaction = async (userDocID) => {
	let user = "";
	let docID = "";

	try {
		await runTransaction(db, async (transaction) => {
			const usersCollectionRef = collection(db, "Users")
			const userDocRef = doc(usersCollectionRef, userDocID);
			const querySnapshot = await transaction
				.get(userDocRef);

			user = querySnapshot.data();
			docID = querySnapshot.id;
		});
	} catch (e) {
		console.error(e);
	}

	return { user: user, docID: docID };
};

export const fetchUsers = async () => {
	let users = "";
	try {
		await db
			.collection("Users")
			.get()
			.then((querySnapshot) => {
				users = querySnapshot;
			});
		return users;
	} catch (e) {
		console.log(e);
	}
};

export const fetchUserDocByID = async (userID) => {

	let userDoc = "";

	try {
		const q = query(collection(db, "Users"), where("userID", "==", userID));

		await getDocs(q)
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					userDoc = doc;

				});
			});
	} catch (e) {
		console.log(e);
	}
	return userDoc;
};

export const fetchEmployerByConfirmationID = async (employerConfirmationID) => {
	let userDoc = "";
	try {
		await db
			.collection("Users")
			.where("employerConfirmationID", "==", employerConfirmationID)
			.where("employerOrEmployee", "==", "employer")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					userDoc = doc;
				});
			});
		return userDoc;
	} catch (e) {
		console.log(e);
	}
};

export const fetchUserField = async (docID) => {
	let userDoc = "";
	await db
		.collection("Users")
		.doc(docID)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				userDoc = doc.id;
			});
		})
		.catch((e) => console.log(e));
	return userDoc;
};

export const fetchUserProviders = async (receiverCoord, blockedUsers = []) => {
	try {
		const serviceProviderList = [];
		await db
			.collection("Users")
			.where("userType", "==", "service provider")
			.where("employerOrEmployee", "==", "employer")
			.get()
			.then(async (snap) => {
				for (const doc of snap.docs) {
					const {
						address,
						streetAddress,
						companyName,
						userID,
						image,
						category,
						stripeConnectAccount,
						reviewAverage,
						reviewList,
						interval,
						intervalTimeUnit,
						email,
						phoneNumber,
						range,
						mobileLocation,
					} = doc.data();
					await getBlockedUsers(userID).then(
						(servicesBlockedUsers) => {
							if (
								!blockedUsers?.includes(userID) &&
								!servicesBlockedUsers.includes(
									auth.currentUser.uid
								)
							) {
								if (
									serviceProviderList.findIndex(
										(e) => e[0].title == category
									) == -1
								) {
									serviceProviderList.push([
										{
											title: category,
											data: [
												{
													id: doc.id,
													userID: userID,
													companyName: companyName,
													address: address,
													streetAddress:
														streetAddress,
													image: image,
													stripeConnectAccount:
														stripeConnectAccount,
													distance:
														calculateGeoDistance(
															address,
															receiverCoord
														),
													reviewAverage:
														reviewAverage,
													reviewList: reviewList,
													interval: interval,
													intervalTimeUnit:
														intervalTimeUnit,
													phoneNumber: phoneNumber,
													email: email,
													range: range,
													mobileLocation:
														mobileLocation,
												},
											],
										},
									]);
								} else {
									var indexToPushInto =
										serviceProviderList.findIndex(
											(e) => e[0].title == category
										);
									serviceProviderList[
										indexToPushInto
									][0].data.push({
										id: doc.id,
										userID: userID,
										companyName: companyName,
										address: address,
										streetAddress: streetAddress,
										image: image,
										stripeConnectAccount:
											stripeConnectAccount,
										distance: calculateGeoDistance(
											address,
											receiverCoord
										),
										reviewAverage: reviewAverage,
										reviewList: reviewList,
										interval: interval,
										intervalTimeUnit: intervalTimeUnit,
										mobileLocation: mobileLocation,
									});
								}
							}
						}
					);
				}
			});
		return serviceProviderList;
	} catch (e) {
		console.log(e);
	}
};

export const fetchReviews = async (userID) => {
	let reviewListTemp = "";
	let futureReviewPossibleTemp = "";
	await db
		.collection("Users")
		.where("userID", "==", userID)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const { review, futureReviewPossible } = doc.data();
				reviewListTemp = review;
				futureReviewPossibleTemp = futureReviewPossible;
			});
		})
		.catch((e) => console.log(e));

	return {
		reviewListTemp: reviewListTemp,
		futureReviewPossibleTemp: futureReviewPossibleTemp,
	};
};

export const updateUserField = async (userDocID, updateField) => {
	await db
		.collection("Users")
		.doc(userDocID)
		.update(updateField)
		.then(() => { })
		.catch((e) => {
			console.log(e);
		});
};

export const updateUserFieldPromise = (userDocID, updateField) => {
	return new Promise(async (resolve, reject) => {
		try {
			await runTransaction(db, async (transaction) => {
				const usersCollectionRef = collection(db, "Users")
				const userDocRef = doc(usersCollectionRef, userDocID);
				transaction.update(userDocRef, updateField);
			}).then(() => {
				resolve("Update successful");
			})
		} catch (error) {
			console.error(error);
			reject(error);
		}
	});
};

export const getServices = async (receiverCoord) => {
	try {
		const serviceList = [];
		await db
			.collection("Users")
			.orderBy("companyName")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					const {
						address,
						streetAddress,
						companyName,
						userID,
						userType,
						Image,
						category,
					} = doc.data();

					if (
						isInRange(receiverCoord, address) &&
						doc.data().userType == "service provider"
					) {
						serviceList.push({
							id: doc.id,
							userID: userID,
							companyName: companyName,
							address: address,
							streetAddress: streetAddress,
							image: Image,
							distance: calculateGeoDistance(
								address,
								receiverCoord
							),
						});
					}
				});
			});

		return serviceList;
	} catch (e) {
		console.log(e);
	}
};

export const addNewUser = async (user) => {
	try {
		await db.collection("Users").add(user);
	} catch (e) {
		console.log(e);
	}
};

export async function getBlockedUsers(userID = auth.currentUser.uid) {
	return fetchUserByID(userID).then((userData) => {
		return userData.blockedUsers;
	});
}

//TODO: add upload user image function in ServiceRev and ServProv.js
