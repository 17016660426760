import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { firebaseApp } from '../../index';


const db = getFirestore(firebaseApp);

export const fetchServicesDocs = async () => {
	return await db
		.collection("Services")
		.get()
		.then((services) => {
			return services;
		});
};

export const addService = async (service) => {
	let docID = "";
	try {
		const doc = await db.collection("Services").add(service);
		docID = doc.id;
	} catch (error) {
		console.error("Error adding service: ", error);
	}
	return docID;
};

export const deleteService = async (serviceID) => {
	try {
		await fetchServicesDocs().then((services) => {
			services.forEach((service) => {
				if (service.id == serviceID) {
					db.collection("Services").doc(serviceID).delete();
				}
			});
			return serviceID;
		});
	} catch (e) {
		console.log(e);
	}
};

export const setServices = async (serviceID, newService) => {
	try {
		await fetchServicesDocs().then((services) => {
			services.forEach((service) => {
				if (service.id === serviceID) {
					db.collection("Services").doc(serviceID).update(newService);
					return;
				}
			});
		});
	} catch (e) {
		console.log(e);
	}
};

export const fetchServicesByID = async (userID) => {
	const serviceList = [];

	try {
		const q = query(collection(db, "Services"), where("user", "==", userID));
		await getDocs(q)
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					const {
						name,
						description,
						price,
						priceUnit,
						priceID,
						image,
						created,
						productID,
						serviceType,
						cancellationFee,
						cancellationFeePriceID,
						cancellationFeeProductID,
						optionsAvailable,
						interval,
						intervalTimeUnit,
						payInStoreActive,
					} = doc.data();

					serviceList.push({
						id: doc.id,
						name: name,
						description: description,
						price: price,
						priceUnit: priceUnit,
						priceID: priceID,
						image: image,
						created: created,
						productID: productID,
						serviceType: serviceType,
						cancellationFee: cancellationFee,
						cancellationFeePriceID: cancellationFeePriceID,
						cancellationFeeProductID: cancellationFeeProductID,
						optionsAvailable: optionsAvailable,
						interval: interval,
						intervalTimeUnit: intervalTimeUnit,
						payInStoreActive: payInStoreActive,
					});
				});
			});
	} catch (e) {
		console.log(e);
	}
	return serviceList;

};
